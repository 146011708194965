import React, { useReducer } from "react";
import ModalBuilderContentFormContext from "./modalBuilderContentFormContext";
import ModalBuilderContentFormReducer from "./modalBuilderContentFormReducer";
import {
	SET_FORM,
	SET_FORM_CONTROLS,
	SET_FORM_DATA,
	RESET_FORM
} from "../types";

const ModalBuilderContentFormState = props => {
	const initialState = {
		form: null,
		formControls: [],
		data: []
	}

	const [ state, dispatch ] = useReducer(ModalBuilderContentFormReducer, initialState);

	const setForm = (form) => {
		dispatch({
			type: SET_FORM,
			payload: form
		});
	};

	const setFormControls = (data) => {
		dispatch({
			type: SET_FORM_CONTROLS,
			payload: data
		});
	};

	const setFormData = (data) => {
		dispatch({
			type: SET_FORM_DATA,
			payload: data
		});
	};

	const resetForm = () => {
		dispatch({
			type: RESET_FORM
		});
	};

	return <ModalBuilderContentFormContext.Provider
		value={{
			form: state.form,
			formControls: state.formControls,
			data: state.data,
			setForm,
			setFormControls,
			setFormData,
			resetForm
		}}
	>
		{props.children}
	</ModalBuilderContentFormContext.Provider>
}

export default ModalBuilderContentFormState;