import {
	SET_FORM,
	SET_FORM_CONTROLS,
	SET_FORM_DATA,
	RESET_FORM
} from "../types";

export default (state, action) => {
	switch (action.type) {
		case SET_FORM:
			return {
				...state,
				form: action.payload
			}
		case SET_FORM_CONTROLS:
			return {
				...state,
				formControls: action.payload
			}
		case SET_FORM_DATA:
			return {
				...state,
				data: action.payload
			}
		case RESET_FORM:
			state.form.reset();
			state.form.clearErrors();
			return {
				...state,
				formControls: []
			}
		default:
			return state;
	}
}