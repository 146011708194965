import {
	GET_CHATS_SUCCESS,
	GET_CHATS_FAIL,
	APPEND_CHAT,
	SEND_CHAT_SUCCESS,
	SEND_CHAT_FAIL,
	RESET_CHATS_SUCCESS,
	RESET_CHATS_FAIL,
	SET_LOADING,
	SET_CHAT_LOADING,
	SET_RESET_LOADING
} from "../types";

const chatReducer = (state, action) => {
	switch (action.type) {
		case GET_CHATS_SUCCESS:
			return {
				...state,
				chats: action.payload,
				loading: false,
			}
		case GET_CHATS_FAIL:
			return {
				...state,
				loading: false,
			}
		case APPEND_CHAT:
			return {
				...state,
				chats: [...state.chats.concat([action.payload])],
			}
		case SEND_CHAT_SUCCESS:
			return {
				...state,
				chats: [...state.chats.concat([action.payload])],
				chatLoading: false,
			}
		case SEND_CHAT_FAIL:
			return {
				...state,
				chatLoading: false,
			}
		case RESET_CHATS_SUCCESS:
			return {
				...state,
				chats: [],
				resetLoading: false,
			}
		case RESET_CHATS_FAIL:
			return {
				...state,
				resetLoading: false,
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case SET_CHAT_LOADING:
			return {
				...state,
				chatLoading: true
			}
		case SET_RESET_LOADING:
			return {
				...state,
				resetLoading: true
			}
		default:
			return state;
	}
}

export default chatReducer