import React, { useState } from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from '@mui/styles';
import {
    Checkbox,
    FormControl,
    InputLabel,
    MenuItem,
    Select as SelectMUI
} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    'normal': {
        '& .MuiOutlinedInput-root': {
            // backgroundColor: '#e6ffec',
            // padding: '0.75rem 1.25rem',
            // fontSize: '1rem',
            // lineHeight: '1.5',
            // borderRadius: '0.25rem'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.75rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem'
        },
    }
}));

const SelectCheckboxes = ({ name, label, items }) => {
    const { control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={[]}
            render={({ field }) => (
                <FormControl style={{ width: '100%' }}>
                    {label && <InputLabel>{label}</InputLabel>}
                    <SelectMUI
                        fullWidth
                        multiple
                        label={label}
                        {...field}
                        renderValue={(selected) =>
                            selected
                            .map((value) => items[value])
                            .join(', ')
                        }
                    >
                        {items &&
                            Object.keys(items).map((name) => (
                                <MenuItem key={name} value={name}>
                                    <Checkbox
                                        checked={field.value.includes(name)}
                                    />
                                    {items[name]}
                                </MenuItem>
                            ))}
                    </SelectMUI>
                </FormControl>
            )}
        />
    );
};

export default SelectCheckboxes;
