import React, {useEffect, useState} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { Avatar } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CardCheckboxControl = ({form, formControl}) => {
	const {
		formState: { errors },
		getValues,
		setValue
	} = form;

	const [enabledAttributes, setEnabledAttributes] = useState(formControl.value ?? []);

	const selectCard = (resourceCode) => {
		const existingIndex = enabledAttributes.indexOf(resourceCode);

		if (existingIndex === -1) {
			setEnabledAttributes([...enabledAttributes, resourceCode]);
			setValue(formControl.name, [...enabledAttributes, resourceCode]);
		} else {
			const updatedValues = [...enabledAttributes];
			updatedValues.splice(existingIndex, 1);
			setEnabledAttributes(updatedValues);
			setValue(formControl.name, updatedValues);
		}
	}

	return (
		<Row>
			{formControl.options.map(option => (
				<Col xs={option.col ?? 12}>
					<Card className={`${enabledAttributes.includes(option.resourceCode) ? "bg-light border-primary" : ''} m-0 my-2 p-0`} key={option.resourceCode}>
						<Card.Body className={`d-flex flex-column align-items-center`} onClick={() => selectCard(option.resourceCode)}>
							<Avatar variant="rounded" className={`${enabledAttributes.includes(option) ? "bg-primary" : ''} m-1 p-4`}><FontAwesomeIcon icon={option.icon ?? 'home'} size="xl"/></Avatar>
							<h5 className="my-2 p-0">{option.label}</h5>
							<p className="m-0 p-0">{option.description}</p>
						</Card.Body>
					</Card>
				</Col>
			))}
		</Row>
	);
}

export default CardCheckboxControl