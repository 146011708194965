import React, { useReducer, useContext } from "react";
import api from "../../network/Api";
import UsersContext from "./usersContext";
import UsersReducer from "./usersReducer";
import {
	GET_USERS,
	GET_LOGGED_IN_USER,
	GET_LOGGED_IN_USER_FAIL,
	CREATE_USER,
	UPDATE_USER,
	REMOVE_USER,
	REMOVE_PENDING_USER,
	INVITE_USER,
	UPDATE_USER_ROLES,
	SET_LOADING,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_RESET_LOADING
} from "../types";

import AlertContext from "../../context/alert/alertContext";
import {default as axios} from "axios";

const UsersState = props => {
	const initialState = {
		users: [],
		pendingUsers: [],
		user: null,
		userAuthentication: null,
		success: null,
		error: null,
		loading: true
	}

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	const [ state, dispatch ] = useReducer(UsersReducer, initialState);

	const getUsers = async (workspaceIdentifier) => {
		setLoading();

		let details = {
			users: [],
			pendingUsers: []
		};

		const response = api.get(`/core/v2/workspaces/${workspaceIdentifier}/users`, {transformRequest: (data, headers) => {
				delete headers.common['Workspace-Identifier'];
				return data;
			}
		});

		const response2 = api.get(`/core/v2/workspaces/${workspaceIdentifier}/pending-users`, {transformRequest: (data, headers) => {
				delete headers.common['Workspace-Identifier'];
				return data;
			}
		});

		await axios.all([response, response2])
			.then(axios.spread((...responses) => {
				details.users = responses[0].data;
				details.pendingUsers = responses[1].data;

				dispatch({
					type: GET_USERS,
					payload: details
				});
			}))
			.catch(errors => {
				setAlert('Something went wrong', 'User retrieval error. Please try again later or email support@botscheduling.com.', 'danger');
				resetLoading();
			});
	};

	//pass in the auth token
	const getLoggedInUser = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		const token = localStorage.getItem('token');

		try {
			if (!token) {
				throw new Error('Parameter is not a number!');
			}

			const res = await api.get(`/core/v2/users/current`, {transformRequest: (data, headers) => {
					delete headers.common['Workspace-Identifier'];
					return data;
				}
			});

			dispatch({
				type: GET_LOGGED_IN_USER,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: GET_LOGGED_IN_USER_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const createUser = async (user) => {
		setLoading();

		const parameters = {
			"name": user.name,
			"email": user.email,
			"password": user.password
		}

		const res = await api.post(`/core/v2/users`, JSON.stringify(parameters), {transformRequest: (data, headers) => {
				delete headers.common['Workspace-Identifier'];
				return data;
			}
		});

		dispatch({
			type: CREATE_USER,
			payload: res.data
		});
	};

	const updateUser = async (user) => {
		setLoading();

		await api.patch(`/core/v2/users/current`, user, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				setAlert('Something went wrong', 'Unable to retrieve resource. Please try again later or email support@botscheduling.com.', 'danger');
			} else {
				setAlert('Success', 'Successfully removed user from workspace.', 'success');

				dispatch({
					type: UPDATE_USER,
					payload: response.data
				});
			}
			setLoading(false);
		}).catch(function (error) {
			setAlert('Remove Customer Error', error.response.data.message, 'danger');
			setLoading(false);
		});
	};

	//this is more a "remove" from workspace
	const removeUser = async (workspaceIdentifier, user) => {
		setLoading();

		await api.delete(`/core/v2/workspaces/${workspaceIdentifier}/users/${user.userIdentifier}`, {
			validateStatus: function (status) {
				return status === 204 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				setAlert('Something went wrong', 'Unable to retrieve resource. Please try again later or email support@botscheduling.com.', 'danger');
			} else {
				setAlert('Success', 'Successfully removed user from workspace.', 'success');

				dispatch({
					type: REMOVE_USER,
					payload: user.userIdentifier
				});
			}
			setLoading(false);
		}).catch(function (error) {
			setAlert('Remove Customer Error', error.response.data.message, 'danger');
			setLoading(false);
		});
	};

	const removePendingUser = async (workspaceIdentifier, pendingUser) => {
		setLoading();

		await api.delete(`/core/v2/workspaces/${workspaceIdentifier}/pending-users/${pendingUser.identifier}`, {
			validateStatus: function (status) {
				return status === 204 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				setAlert('Something went wrong', 'Unable to remove pending user. Please try again later or email support@botscheduling.com.', 'danger');
			} else {
				setAlert('Success', 'Successfully removed user from workspace.', 'success');

				dispatch({
					type: REMOVE_PENDING_USER,
					payload: pendingUser.userIdentifier
				});
			}
			resetLoading();
		}).catch(function (error) {
			setAlert('Remove Pending User Error', error.response.data.message, 'danger');
			resetLoading();
		});
	};

	const inviteUser = async (workspaceIdentifier, email) => {
		const data = [
			{
				"email": email
			}
		]

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			await api.post(`/core/v2/workspaces/${workspaceIdentifier}/invite`, JSON.stringify(data), config);

			setAlert('Success!', 'Successfully invited user!', 'success')

			getUsers(workspaceIdentifier);

			dispatch({
				type: INVITE_USER
			});
		} catch (err) {
			setAlert('Invite User Error', err.response.data.message, 'danger')
		}
	};


	const setRoles = async (userIdentifier, roles) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			await api.patch(`/core/v2/users/${userIdentifier}/roles`, roles, config);

			setAlert('Success!', 'Roles successfully updated', 'success')

			dispatch({
				type: UPDATE_USER_ROLES
			});
		} catch (err) {
			setAlert('Update Role Error', err.response.data.message, 'danger')
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	const resetLoading = () => dispatch({ type: SET_RESET_LOADING });

	return <UsersContext.Provider
		value={{
			users: state.users,
			pendingUsers: state.pendingUsers,
			user: state.user,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getUsers,
			getLoggedInUser,
			createUser,
			updateUser,
			removeUser,
			removePendingUser,
			inviteUser,
			setRoles,
			resetSuccess,
			resetError,
			setLoading,
			resetLoading
		}}
	>
		{props.children}
	</UsersContext.Provider>
}

export default UsersState;