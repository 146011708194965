import {
	INIT_MODAL,
	SHOW_MODAL,
	HIDE_MODAL,
	RESET_MODAL,
	SET_MESSAGE,
	SET_SUBMIT_BUTTON,
	SET_CANCEL_BUTTON,
	SET_TERTIARY_BUTTON,
	SET_SUBMIT_HANDLER,
	SET_CANCEL_HANDLER,
	SET_TERTIARY_HANDLER
} from "../types";

export default (state, action) => {
	switch (action.type) {
		case INIT_MODAL:
			return {
				...state,
				title: action.payload.title,
				content: action.payload.content
			}
		case SHOW_MODAL:
			return {
				...state,
				show: true
			}
		case HIDE_MODAL:
			return {
				...state,
				show: false
			}
		case RESET_MODAL:
			return {
				...state,
				show: false,
				formControls: [],
				message: null,
				submitButton: {
					text: "SUBMIT",
					variant: "primary"
				},
				cancelButton: {
					text: "CANCEL",
					variant: "link"
				},
				tertiaryButton: null
			}
		case SET_MESSAGE:
			return {
				...state,
				message: action.payload
			}
		case SET_SUBMIT_BUTTON:
			return {
				...state,
				submitButton: action.payload
			}
		case SET_CANCEL_BUTTON:
			return {
				...state,
				cancelButton: action.payload
			}
		case SET_TERTIARY_BUTTON:
			return {
				...state,
				tertiaryButton: action.payload
			}
		case SET_SUBMIT_HANDLER:
			return {
				...state,
				submitHandler: action.payload
			}
		case SET_CANCEL_HANDLER:
			return {
				...state,
				cancelHandler: action.payload
			}
		case SET_TERTIARY_HANDLER:
			return {
				...state,
				tertiaryHandler: action.payload
			}
		default:
			return state;
	}
}