import { useContext } from 'react';

import AuthContext from "../context/auth/authContext";

const CheckAccess = (permission) => {
	const authContext = useContext(AuthContext);
	const { authenticationToken, userAccess } = authContext;

	if (authenticationToken === null) {
		return false;
	}

	if (!Array.isArray(userAccess)) {
		return false;
	}

	const permissionPieces = permission.split('|');
	const intersection = permissionPieces.filter(item => userAccess.includes(item));

	return userAccess && intersection.length > 0;
}

export default CheckAccess;