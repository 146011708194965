import React, { useContext, useReducer } from "react";

import LoadingContext from "./loadingContext";
import LoadingReducer from "./loadingReducer";
import {
	SET_INITIAL_PAGE_LOADING,
	SET_DATA_LOADING,
	SET_PAGE_LOADING
} from "../types";

const LoadingState = props => {
	const initialState = {
		initialPageLoading: false,
		dataLoading: false,
		pageLoading: false
	}

	const [ state, dispatch ] = useReducer(LoadingReducer, initialState);

	const setInitialPageLoading = () => dispatch({ type: SET_INITIAL_PAGE_LOADING });
	const setDataLoading = () => dispatch({ type: SET_DATA_LOADING });
	const setPageLoading = () => dispatch({ type: SET_PAGE_LOADING });

	return <LoadingContext.Provider
		value={{
			initialPageLoad: state.initialPageLoad,
			dataLoading: state.dataLoading,
			pageLoading: state.pageLoading,
			setInitialPageLoading,
			setDataLoading,
			setPageLoading,
		}}
	>
		{props.children}
	</LoadingContext.Provider>
}

export default LoadingState;