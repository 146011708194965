import {
	GET_BILLING_DETAILS,
	GET_AVAILABLE_PRODUCTS,
	CHANGE_SUBSCRIPTION,
	UPDATE_PAYMENT,
	DELETE_PAYMENT,
	SET_LOADING
} from "../types";

const billingReducer = (state, action) => {
	switch (action.type) {
		case GET_BILLING_DETAILS:
			return {
				...state,
				subscriptions: action.payload.subscriptions,
				payments: action.payload.payments,
				transactions: action.payload.transactions,
				loading: false
			}
		case GET_AVAILABLE_PRODUCTS:
			return {
				...state,
				availableProducts: action.payload,
				loading: false
			}
		case CHANGE_SUBSCRIPTION:
			return {
				...state,
				loading: false
			}
		case UPDATE_PAYMENT:
			return {
				...state,
				payments: state.payments.map(
					payment => {
						var temporaryPayment = Object.assign({}, payment);
						temporaryPayment.default = (temporaryPayment.identifier === action.payload)
						return temporaryPayment
					}
				),
				loading: false
			}
		case DELETE_PAYMENT:
			return {
				...state,
				payments: state.payments.filter(
					payment => payment.identifier !== action.payload
				),
				loading: false
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default billingReducer