import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import ChatContext from "./chatContext";
import ChatReducer from "./chatReducer";
import {
	GET_CHATS_SUCCESS,
	GET_CHATS_FAIL,
	APPEND_CHAT,
	SEND_CHAT_SUCCESS,
	SEND_CHAT_FAIL,
	RESET_CHATS_SUCCESS,
	RESET_CHATS_FAIL,
	SET_LOADING,
	SET_CHAT_LOADING,
	SET_RESET_LOADING
} from "../types";
import { format } from "date-fns";

const ChatState = props => {
	const initialState = {
		chats: [],
		loading: true,
		chatLoading: false,
		resetLoading: false
	}

	const [ state, dispatch ] = useReducer(ChatReducer, initialState);

	const getChats = async () => {
		setLoading();

		await api.get(`/book/v1/chat`, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				props.alertContext.setAlert('Error', 'Enable to load chat history, please try again soon.', 'danger');
				dispatch({
					type: GET_CHATS_FAIL,
					payload: null
				});
			} else {
				dispatch({
					type: GET_CHATS_SUCCESS,
					payload: response.data
				});
			}
		}).catch(function (error) {
			props.alertContext.setAlert('Whoops', error.response?.data?.message ?? 'An unknown error has occurred.', 'danger');
			dispatch({
				type: GET_CHATS_FAIL,
				payload: null
			});
		});
	};

	const sendChat = async (chatMessage) => {
		setChatLoading();

		dispatch({
			type: APPEND_CHAT,
			payload: {
				"identifier": "",
				"conversationIdentifier": "",
				"content": chatMessage.trim(),
				"role": "user",
				"createdOn": format(new Date(), "yyyy-MM-dd HH:mm:ss")
			}
		});

		const data = {
			"content": chatMessage.trim()
		};

		await api.post(`/book/v1/chat`, data, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				dispatch({
					type: SEND_CHAT_FAIL
				});
			} else {
				dispatch({
					type: SEND_CHAT_SUCCESS,
					payload: response.data
				});
			}
		}).catch(function (error) {
			props.alertContext.setAlert('Whoops', error.response?.data?.message ?? 'An unknown error has occurred.', 'danger');
			dispatch({
				type: SEND_CHAT_FAIL
			});
		});
	};

	const resetChat = async () => {
		setResetLoading();

		await api.post(`/book/v1/chat/conversation`, null, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			dispatch({
				type: RESET_CHATS_SUCCESS
			});
		}).catch(function (error) {
			dispatch({
				type: RESET_CHATS_FAIL
			});
		});
	};

	const setLoading = () => dispatch({ type: SET_LOADING });
	const setChatLoading = () => dispatch({ type: SET_CHAT_LOADING });
	const setResetLoading = () => dispatch({ type: SET_RESET_LOADING });

	return <ChatContext.Provider
		value={{
			chats: state.chats,
			loading: state.loading,
			chatLoading: state.chatLoading,
			resetLoading: state.resetLoading,
			getChats,
			sendChat,
			resetChat,
			setLoading,
			setChatLoading,
			setResetLoading
		}}
	>
		{props.children}
	</ChatContext.Provider>
}

export default ChatState;