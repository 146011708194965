import React, { useState, useEffect, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Button, SimpleModal } from "../../../components/layout/BotSchedulingUI";
import FormCreator from "../../../components/formcreator/FormCreator";
import ResourceManagementLabel from "../../../utils/ResourceManagementLabel";
import TimeZoneList from "../../../data/TimeZoneList.json";
import { CUSTOMER_RESOURCE_CODE } from "../../../constants";

import api from "../../../network/Api";

import CustomersContext from "../../../context/customers/customersContext";

const CreateCustomerModal = ({displayModal, handleCloseModal, loadResources, ...props}) => {
	const customerSingular = ResourceManagementLabel(CUSTOMER_RESOURCE_CODE, 'singular', 'case');

	const customersContext = useContext(CustomersContext);

	const resourceManagement = props.resources.filter(resource => resource.resourceCode === CUSTOMER_RESOURCE_CODE)[0];

	const [loading, setLoading] = useState(props.loading ?? false);
	const [formControls, setFormControls] = useState([
		{
			label: "Full Name *",
			type: "input",
			name: "name",
			value: "",
			size: "lg",
			validationRules: {
				required: {
					value: true,
					message: "Name is required."
				},
				minLength: {
					value: 3, message: "Name must be 2 or more characters." },
			}
		},
		{
			label: "Email Address *",
			type: "input",
			name: "email",
			value: "",
			size: "lg",
			validationRules: {
				required: {
					value: true,
					message:"Email is required."
				},
				pattern: {
					value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
					message: "Email address is not valid."
				}
			}
		},
		{
			label: "Time Zone *",
			type: "searchablelist",
			name: "timeZone",
			value: "",
			size: "lg",
			options: TimeZoneList,
			validationRules: {
				required: {
					value: true,
					message: "Time Zone is required."
				}
			}
		}
	]);

	useEffect(() => {
		const fields = getFields();
		setFormControls([...formControls, ...fields]);
		// setFormControls([]);
	}, []);

	const getFields = () => {
		return resourceManagement.fields.map(field => {
			const validationRules = {
				required: {
					value: true,
					message: "Field is required"
				},
			};

			const options = Array.isArray(field.fieldValues) ? field.fieldValues.map(value => {
				return {"key" : value, "label": value}
			}) : null;

			const fieldProperties = {
				label: `${field.label}${field.isRequired ? ' *' : ''}`,
				type: field.type === 'textfield' ? 'text' : field.type,
				name: `cf_${field.name}`,
				value: "",
				size: "lg",
				placeholder: "",
				validationRules: field.isRequired ? validationRules : {}
			};

			if (options !== null) {
				fieldProperties.options = options;
			}

			return fieldProperties;
		});
	}

	const createCustomer = (data) => {
		const apiData = {
			avatarUrl: null,
			...data
		}

		setLoading(true)

		api.post(`/core/v2/persons`, apiData, {
			validateStatus: function (status) {
				return status === 201 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				props.alertContext.setAlert('Something went wrong', 'Unable to create record. Please try again later or email support@botscheduling.com.', 'danger');
			} else {
				props.alertContext.setAlert('Success', resourceManagement.singularName + ' successfully created', 'success');
				customersContext.loadCustomers(50, 20); //todo these need to be optional.
				closeModal();
			}
			setLoading(false);
		}).catch(function (error) {
			props.alertContext.setAlert('Whoops', error.response?.data?.message ?? 'An unknown error has occurred.', 'danger');
			setLoading(false);
		});
	}

	const [ form ] = useState(useForm({
		mode: 'all',
	}));

	const {
		handleSubmit,
		reset
	} = form;

	const closeModal = () => {
		setLoading(false);
		reset();
		handleCloseModal();
	}

	return (
		<SimpleModal header={`Create ${customerSingular}`} show={displayModal} size="xl" onHide={closeModal} footer={
			<>
				<div>

				</div>
				<div>
					<Button
						variant="link"
						onClick={closeModal}
						text="Cancel"
						disabled={loading}
						keyDown={(event) => {
							{event.ctrlKey && event.key === 'c' &&
								closeModal();
							}
						}}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={() => handleSubmit(createCustomer)()}
						loading={loading}
					>
						{loading ? "Creating..." : "Create"}
					</Button>
				</div>
			</>
		}>
			<FormCreator form={form} formControls={formControls} onSubmit={createCustomer} />
		</SimpleModal>
	);
}

export default CreateCustomerModal