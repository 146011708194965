import React from "react";
import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

const OBBreadcrumbItem = ({ to, text }) => {
    if (to !== '') {
        return (
            <Breadcrumb.Item linkAs={Link} linkProps={{to}}>{text}</Breadcrumb.Item>
        );
    } else {
        return (
            <Breadcrumb.Item active>{text}</Breadcrumb.Item>
        );
    }
}

export default OBBreadcrumbItem;