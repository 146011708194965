import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import {
    FormControl,
    InputLabel,
    MenuItem,
    Select as SelectMUI,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles((theme) => ({
    normal: {
        '& .MuiOutlinedInput-root': {
            // backgroundColor: '#e6ffec',
            // padding: '0.75rem 1.25rem',
            // fontSize: '1rem',
            // lineHeight: '1.5',
            // borderRadius: '0.25rem'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.75rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem',
        },
    },
}));

const SelectIcons = ({ name, label, rules, defaultValue, items }) => {
    const classes = useStyles();
    const { formState: { errors }, control, setValue } = useFormContext();

    // State to hold the selected value
    const [selectedValue, setSelectedValue] = useState(defaultValue ?? '');

    // Update form data when selectedValue changes

    useEffect(() => {
        setValue(name, selectedValue);
    }, [name, selectedValue, setValue]);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={rules ?? {}}
            render={({ field, fieldState: { invalid } }) => (
                <>
                    {label && <InputLabel>{label}</InputLabel>}
                    <SelectMUI
                        {...field}
                        fullWidth
                        label={label ?? undefined}
                        className={classes.normal}
                        size="small"
                        defaultValue={defaultValue ?? undefined}
                        onChange={handleChange} // Handle selection changes
                        value={selectedValue} // Set the selected value from state
                    >
                        {items &&
                            Object.keys(items).map((key) => (
                                <MenuItem key={key} value={key}>
                                    <FontAwesomeIcon icon={items[key]} className="pr-2" />{items[key]}
                                </MenuItem>
                            ))}
                    </SelectMUI>
                </>
            )}
        />
    );
};

export default SelectIcons;