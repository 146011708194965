import React, { useReducer, useContext } from "react";
import api from "../../network/Api";
import AccessContext from "./accessContext";
import AccessReducer from "./accessReducer";
import {
	LOAD_ACCESS_DETAILS,
	LOAD_PERMISSIONS_BY_ROLE,
	CREATE_ROLE,
	CREATE_ROLE_FAILED,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_FAIL,
	UPLOAD_PERMISSIONS_FOR_ROLE,
	DELETE_ROLE,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
} from "../types";

import AlertContext from "../alert/alertContext";

import {default as axios} from "axios";

const AccessState = props => {
	const initialState = {
		roles: [],
		permissions: [],
		rolePermissions: [],
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(AccessReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	const loadAccessDetails = async () => {
		const details = {
			roles: [],
			permissions: []
		}

		const res = api.get(`/core/v2/access/roles`, {transformRequest: (data, headers) => {
				return data;
			}
		});

		const res2 = api.get(`/core/v2/access/permissions`, {transformRequest: (data, headers) => {
				return data;
			}
		});

		await axios.all([res, res2])
			.then(axios.spread((...responses) => {
				details.roles = responses[0].data;
				details.permissions = responses[1].data;
			}))
			.catch(errors => {
				//todo do something here?
			});

		dispatch({
			type: LOAD_ACCESS_DETAILS,
			payload: details
		});
	};

	const loadPermissionsByRole = async (roleIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/access/roles/${roleIdentifier}/permissions`, config);

			dispatch({
				type: LOAD_PERMISSIONS_BY_ROLE,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: LOAD_PERMISSIONS_BY_ROLE,
				payload: []
			});
		}
	};

	const updatePermissionsForRole = async (roleIdentifier, permissions) => {
		setLoading();

		const data = {
			permissions: permissions
		}

		try {
			const res = await api.patch(`/core/v2/access/roles/${roleIdentifier}/permissions`, data, {
				headers: {
					'Content-Type': 'application/json'
				},
				validateStatus: function (status) {
					return status === 200;
				}
			});

			dispatch({
				type: UPLOAD_PERMISSIONS_FOR_ROLE
			});
		} catch (err) {
			dispatch({
				type: UPLOAD_PERMISSIONS_FOR_ROLE
			});
		}
	};

	const createRole = async (data) => {
		setLoading();

		try {
			const res = await api.post(`/core/v2/access/roles`, data, {
				headers: {
					'Content-Type': 'application/json'
				},
				validateStatus: function (status) {
					return status === 201;
				}
			});

			dispatch({
				type: CREATE_ROLE,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_ROLE_FAILED,
				payload: err.response.data.message
			});
		}
	};

	const updateRole = async (roleIdentifier, data) => {
		setLoading();

		try {
			const res = await api.patch(`/core/v2/access/roles/${roleIdentifier}`, data, {
				headers: {
					'Content-Type': 'application/json'
				},
				validateStatus: function (status) {
					return status === 200;
				}
			});

			dispatch({
				type: UPDATE_ROLE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_ROLE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteRole = async (roleIdentifier) => {
		setLoading();

		try {
			const res = await api.delete(`/core/v2/access/roles/${roleIdentifier}`, {
				headers: {
					'Content-Type': 'application/json'
				},
				validateStatus: function (status) {
					return status === 204;
				}
			});

			dispatch({
				type: DELETE_ROLE,
				payload: roleIdentifier
			});
		} catch (err) {
			//alert here or something
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <AccessContext.Provider
		value={{
			roles: state.roles,
			permissions: state.permissions,
			rolePermissions: state.rolePermissions,
			loading: state.loading,
			success: state.success,
			error: state.error,
			loadAccessDetails,
			loadPermissionsByRole,
			updatePermissionsForRole,
			createRole,
			updateRole,
			deleteRole,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</AccessContext.Provider>
}

export default AccessState;