import {
	GET_APPOINTMENTS,
	GET_APPOINTMENT,
	GET_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_APPOINTMENTS_CALENDAR_FAIL,
	GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL,
	GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS,
	GET_PAST_APPOINTMENTS_CALENDAR_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const appointmentsReducer = (state, action) => {
	switch (action.type) {
		case GET_APPOINTMENTS:
			return {
				...state,
				appointments: action.payload,
				loading: false
			}
		case GET_APPOINTMENT:
			return {
				...state,
				appointment: action.payload,
				loading: false
			}
		case GET_APPOINTMENTS_CALENDAR_SUCCESS:
			return {
				...state,
				appointmentsCalendar: action.payload,
				loading: false
			}
		case GET_APPOINTMENTS_CALENDAR_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS:
			return {
				...state,
				futureAppointments: action.payload,
				loading: false
			}
		case GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS:
			return {
				...state,
				pastAppointments: action.payload,
				loading: false
			}
		case GET_PAST_APPOINTMENTS_CALENDAR_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default appointmentsReducer