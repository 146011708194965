import React, { useReducer } from "react";
import ModalContext from "./modalContext";
import ModalReducer from "./modalReducer";
import {
	INIT_MODAL,
	SHOW_MODAL,
	HIDE_MODAL,
	RESET_MODAL,
	SET_MESSAGE,
	SET_SUBMIT_BUTTON,
	SET_CANCEL_BUTTON,
	SET_TERTIARY_BUTTON,
	SET_SUBMIT_HANDLER,
	SET_CANCEL_HANDLER,
	SET_TERTIARY_HANDLER
} from "../types";

const ModalState = props => {
	const initialState = {
		show: false,
		message: null,
		title: null,
		content: null,
		size: 'lg',
		submitButton: {
			text: "SUBMIT",
			variant: "primary"
		},
		cancelButton: {
			text: "CANCEL",
			variant: "link"
		},
		tertiaryButton: null,
		submitHandler: null,
		cancelHandler: null
	}

	const [ state, dispatch ] = useReducer(ModalReducer, initialState);

	//Initial the model
	const initModal = (title, content) => {
		if (typeof title === 'undefined') {
			title = null;
		}

		if (typeof content === 'undefined') {
			content = null;
		}

		dispatch({
			type: INIT_MODAL,
			payload: {
				title: title,
				content: content
			}
		});
	};

	//Displays the model
	const showModal = () => {
		dispatch({
			type: SHOW_MODAL
		});
	};

	//Hides the modal
	const hideModal = () => {
		dispatch({
			type: HIDE_MODAL
		});
	};

	//Show the modal
	const handleSubmit = () => {
		console.log('handleSubmit');
	};

	const resetModal = () => {
		dispatch({
			type: RESET_MODAL
		});
	};

	const clearModal = () => {
		dispatch({
			type: RESET_MODAL,
			payload: {
				show: false,
				message: null,
				title: null,
				content: null,
				submitButton: {
					text: "SUBMIT",
					variant: "primary"
				},
				cancelButton: {
					text: "CANCEL",
					variant: "link"
				},
				tertiaryButton: null,
				submitHandler: null
			}
		});
	};

	const setMessage = (data) => {
		dispatch({
			type: SET_MESSAGE,
			payload: data
		});
	};

	const setSubmitButton = (data) => {
		dispatch({
			type: SET_SUBMIT_BUTTON,
			payload: data
		});
	};

	const setCancelButton = (data) => {
		dispatch({
			type: SET_CANCEL_BUTTON,
			payload: data
		});
	};

	const setTertiaryButton = (data) => {
		dispatch({
			type: SET_TERTIARY_BUTTON,
			payload: data
		});
	};

	const setSubmitHandler = (data) => {
		dispatch({
			type: SET_SUBMIT_HANDLER,
			payload: data
		});
	};

	const setCancelHandler = (data) => {
		dispatch({
			type: SET_CANCEL_HANDLER,
			payload: data
		});
	};

	const setTertiaryHandler = (data) => {
		dispatch({
			type: SET_TERTIARY_HANDLER,
			payload: data
		});
	};

	return <ModalContext.Provider
		value={{
			show: state.show,
			message: state.message,
			title: state.title,
			content: state.content,
			submitButton: state.submitButton,
			cancelButton: state.cancelButton,
			tertiaryButton: state.tertiaryButton,
			submitHandler: state.submitHandler,
			cancelHandler: state.cancelHandler,
			tertiaryHandler: state.tertiaryHandler,
			initModal,
			showModal,
			hideModal,
			handleSubmit,
			resetModal,
			setMessage,
			setSubmitButton,
			setCancelButton,
			setTertiaryButton,
			setSubmitHandler,
			setCancelHandler,
			setTertiaryHandler
		}}
	>
		{props.children}
	</ModalContext.Provider>
}

export default ModalState;