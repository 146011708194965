import React from "react";
import Typography from "../typography/Typography";
import IdentifierView from "../views/partials/identifier/IdentifierView";

const HeadingWithIdentifier = ({identifier, color, className, children}) => {
	return (
		<div className={className ?? 'm-0'}>
			<Typography variant="h1" className={`${color ? `text-${color}` : ''} m-0`}>
				{children}
			</Typography>
			<IdentifierView identifier={identifier} color={color} />
		</div>
	);
}

export default HeadingWithIdentifier;