import React, { useReducer } from "react";
import api from "../../network/Api";
import PermissionsContext from "./permissionsContext";
import PermissionsReducer from "./permissionsReducer";
import {
	GET_PERMISSONS,
	SET_LOADING
} from "../types";

const PermissionsState = props => {
	const initialState = {
		permissions: null,
		loading: false
	}

	const [ state, dispatch ] = useReducer(PermissionsReducer, initialState);

	const getPermissions = async () => {
		setLoading();

 		const res = await api.get(`/core/v2/permissions`);

		dispatch({
			type: GET_PERMISSONS,
			payload: res.data
		});
	};

	// const clearPermissions = () => {
	// 	setPermissions([]);
	// 	setLoading(false);
	// }

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <PermissionsContext.Provider
		value={{
			permissions: state.permissions,
			loading: state.loading,
			getPermissions
		}}
	>
		{props.children}
	</PermissionsContext.Provider>
}

export default PermissionsState;