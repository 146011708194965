import { useContext } from 'react';

import ResourcesContext from "../context/resources/resourcesContext";

const ResourceManagementLabel = (resourceCode, type, toCase) => {
	const resourcesContext = useContext(ResourcesContext);

	if (resourcesContext.resources === null) {
		return '';
	}

	const resourceManagement = resourcesContext.resources.filter(resource => resource.resourceCode === resourceCode)[0];

	if (type === 'plural') {
		if (toCase === 'lower') {
			return resourceManagement.pluralName.toLowerCase();
		} else {
			return resourceManagement.pluralName.charAt(0).toUpperCase() + resourceManagement.pluralName.slice(1);
		}
	}

	if (type === 'singular') {
		if (toCase === 'lower') {
			return resourceManagement.singularName.toLowerCase();
		} else {
			return resourceManagement.singularName.charAt(0).toUpperCase() + resourceManagement.singularName.slice(1);
		}
	}

	return resourceManagement.pluralName;
}

export default ResourceManagementLabel;