import React, { useReducer } from "react";
import api from "../../network/Api";
import AttributesContext from "./attributesContext";
import AttributesReducer from "./attributesReducer";
import {
	LOAD_ATTRIBUTES_SUCCESS,
	LOAD_ATTRIBUTES_FAIL,
	SET_LOADING
} from "../types";

const AttributesState = props => {
	const initialState = {
		attributes: null,
		status: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(AttributesReducer, initialState);

	const loadAttributes = async () => {
		setLoading();

		await api.get(`/core/v1/attributes`, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				dispatch({
					type: LOAD_ATTRIBUTES_FAIL,
					payload: response.status
				});
			} else {
				dispatch({
					type: LOAD_ATTRIBUTES_SUCCESS,
					payload: response.data
				});
			}
		}).catch(function (error) {
			dispatch({
				type: LOAD_ATTRIBUTES_FAIL,
				payload: error.response.status
			});
		});
	};
	
	const setLoading = () => dispatch({ type: SET_LOADING });

	return <AttributesContext.Provider
		value={{
			attributes: state.attributes,
			status: state.status,
			loading: state.loading,
			loadAttributes,
			setLoading
		}}
	>
		{props.children}
	</AttributesContext.Provider>
}

export default AttributesState;