import React, {useContext, useReducer} from "react";
import api from "../../network/Api";
import TicketsContext from "./ticketsContext";
import TicketsReducer from "./ticketsReducer";
import {
	LOAD_TICKETS_SUCCESS,
	LOAD_TICKETS_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
} from "../types";

import AlertContext from "../../context/alert/alertContext";

const TicketsState = props => {
	const initialState = {
		tickets: null,
		success: null,
		error: null,
		status: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(TicketsReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	const loadTickets = () => {
		setLoading();

		api.get(`/core/v2/users/current/tickets`, {
			validateStatus: function (status) {
				return status === 200 || (status >= 500 && status < 600);
			}
		}).then(function (response) {
			if (response.status >= 500 && response.status < 600) {
				dispatch({
					type: LOAD_TICKETS_FAIL,
					payload: response.status
				});
			} else {
				dispatch({
					type: LOAD_TICKETS_SUCCESS,
					payload: response.data
				});
			}
		}).catch(function (error) {
			dispatch({
				type: LOAD_TICKETS_FAIL,
				payload: error.response.status
			});
		});
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <TicketsContext.Provider
		value={{
			tickets: state.tickets,
			success: state.success,
			error: state.error,
			loading: state.loading,
			status: state.status,
			loadTickets,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</TicketsContext.Provider>
}

export default TicketsState;