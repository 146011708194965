import React, { Suspense } from 'react'
import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'

import AppError from './AppError'
import ServiceError from './ServiceError'
import NotFound from './NotFound'
import Loading from "../components/Loading";

import LoggedInAndWorkspaceSelectedRoute from '../routing/LoggedInAndWorkspaceSelectedRoute';
import LoggedInRoute from "../routing/LoggedInRoute";
import NotLoggedInRoute from "../routing/NotLoggedInRoute";

// Routes
import { DefaultLayout, titleTemplate, defaultRoute, routes } from '../routes'

const Router = () => {
    const isRTL = false

    // Set default layout
    routes.map(route => {
        route.layout = route.layout || DefaultLayout
        route.exact = typeof route.exact === 'undefined' ? true : route.exact
        return route
    })

    //TODO: This is replaced by application context
    const setTitle = (title) => {
        document.title = titleTemplate.replace('%s', title)
    };

    const scrollTop = (to, duration, element = document.scrollingElement || document.documentElement) => {
        if (element.scrollTop === to) return

        const start = element.scrollTop
        const change = to - start
        const startDate = +new Date()

        if (!duration) {
          element.scrollTop = to
          return
        }

        // t = current time; b = start value; c = change in value; d = duration
        const easeInOutQuad = (t, b, c, d) => {
            t /= d / 2
            if (t < 1) return c / 2 * t * t + b
            t--
            return -c / 2 * (t * (t - 2) - 1) + b
        }

        const animateScroll = () => {
            const currentDate = +new Date()
            const currentTime = currentDate - startDate
            element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
            if (currentTime < duration) {
              requestAnimationFrame(animateScroll)
            } else {
              element.scrollTop = to
            }
        }

        animateScroll()
    };

    const routeRender = (route, props) => {
        // On small screens collapse sidenav
        if (window.layoutHelpers && window.layoutHelpers.isSmallScreen()) {
          window.layoutHelpers.setCollapsed(true, false)
        }

        // Scroll page to top on route render
        scrollTop(0, 0)

        console.log(route);

        // Return layout
        return (
            //loggedinAndWorkspaceSelectedRoute
            // Layout2
            <route.layout {...props}>
                {/*dashboard*/}
                <route.component {...props} setTitle={setTitle} scrollTop={scrollTop} isRTL={isRTL}/>
            </route.layout>
        );
    };

    return (
        <AppRouter basename={process.env.REACT_APP_BASENAME}>
            <Suspense fallback={<Loading ready={false} />}>
                <Switch>
                    {routes.map(route => {
                        if (route.auth === 'notLoggedIn') {
                            return (<NotLoggedInRoute
                                path={route.path}
                                exact={route.exact}
                                render={props => routeRender(route, props)}
                                key={route.path}
                            />);
                        } else if (route.auth === 'loggedIn') {
                            return (<LoggedInRoute
                                path={route.path}
                                exact={route.exact}
                                render={props => routeRender(route, props)}
                                key={route.path}
                            />);
                        } else if (route.auth === 'public') {
                            return (<Route
                                path={route.path}
                                exact={route.exact}
                                render={props => routeRender(route, props)}
                                key={route.path}
                            />);
                        } else {
                            return (<LoggedInAndWorkspaceSelectedRoute
                                path={route.path}
                                exact={route.exact}
                                render={props => routeRender(route, props)}
                                key={route.path}
                                permissions={route.permissions}
                            />);
                        }
                    })}

                    {defaultRoute !== '/' &&
                        <Redirect from="/" to={defaultRoute} exact={true} />
                    }

                    {/* NotFound page */}
                    <Route path="/app/error" component={ AppError } />
                    <Route path="/app/service" component={ ServiceError } />
                    <Route path="*" component={ NotFound } />
                </Switch>
            </Suspense>
        </AppRouter>
    );
};

export default Router