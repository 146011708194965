import { Controller } from "react-hook-form";
import { TimePicker as TimePickerMUI } from '@mui/x-date-pickers';
import FormHelperText from '@mui/material/FormHelperText';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    'root': {
        '& .MuiOutlinedInput-root': {
            // backgroundColor: '#e6ffec',
            // padding: '0.75rem 1.25rem',
            // fontSize: '1rem',
            // lineHeight: '1.5',
            // borderRadius: '0.25rem'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.5rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem'
        },
    }
}));

const TimePicker = (props) => {
    const classes = useStyles();

    return (
        <Controller
            name={props.name}
            control={props.control}
            rules={props.rules}
            render={({
                field: { onChange, value},
                fieldState: { error},
                formState
            }) => (
                <>
                    <TimePickerMUI
                        size="small"
                        value={value}
                        onChange={onChange}
                        fullWidth
                        label={props.label}
                        variant="outlined"
                        className={classes['root']}
                    />
                    {error && (
                        <FormHelperText error>{error.message}</FormHelperText>
                    )}
                </>
            )}
        />
    );
};

export default TimePicker;