import React, { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import OBBreadcrumbItem from "./OBBreadcrumbItem";

const OBBreadcrumb = ({items = [], loader = null}) => {
    const [config, setConfig] = useState({
        items: items,
        loader: loader
    });

    const setItems = (items) => {
        setConfig({...config, items});
    }

    return (
        <Breadcrumb className="mt-2 mb-4">
            {items.map((item) => {
                return <OBBreadcrumbItem key={item.to} to={item.to} text={item.text} />
            })}
        </Breadcrumb>
    );
}

export default OBBreadcrumb;