import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import SettingsContext from "./settingsContext";
import SettingsReducer from "./settingsReducer";
import {
	LOAD_WORKSPACE_SETTINGS_SUCCESS,
	LOAD_WORKSPACE_SETTINGS_FAIL,
	LOAD_USER_SETTINGS_SUCCESS,
	LOAD_USER_SETTINGS_FAIL,
	LOAD_LOCATION_SETTINGS_SUCCESS,
	LOAD_LOCATION_SETTINGS_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const SettingsState = props => {
	const initialState = {
		workspaceSettings: {},
		userSettings: {},
		locationSettings: {},
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(SettingsReducer, initialState);

	//Load workspace settings
	const loadWorkspaceSettings = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/settings`, config);

			dispatch({
				type: LOAD_WORKSPACE_SETTINGS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: LOAD_WORKSPACE_SETTINGS_FAIL,
				payload: null
			});
		}
	};

	//Load user settings
	const loadUserSettings = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/users/current/settings`, config);

			dispatch({
				type: LOAD_USER_SETTINGS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: LOAD_USER_SETTINGS_FAIL,
				payload: null
			});
		}
	};

	//Load location settings
	const loadLocationSettings = async (locationIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/locations/${locationIdentifier}/settings`, config);

			dispatch({
				type: LOAD_LOCATION_SETTINGS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: LOAD_LOCATION_SETTINGS_FAIL,
				payload: null
			});
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <SettingsContext.Provider
		value={{
			workspaceSettings: state.workspaceSettings,
			userSettings: state.userSettings,
			locationSettings: state.locationSettings,
			success: state.success,
			error: state.error,
			loading: state.loading,
			loadWorkspaceSettings,
			loadUserSettings,
			loadLocationSettings,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</SettingsContext.Provider>
}

export default SettingsState;