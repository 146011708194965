import {
	GET_RESOURCES,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const resourcesReducer = (state, action) => {
	switch (action.type) {
		case GET_RESOURCES:
			return {
				...state,
				resources: action.payload,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default resourcesReducer