import api from "../network/Api";

const setAuthToken = (token) => {
	if (token) {
		api.defaults.headers.common['Authentication-Token'] = token;
	} else {
		delete api.defaults.headers.common['Authentication-Token'];
	}
}

export default setAuthToken;