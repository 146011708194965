import React from "react";
// import { Link } from "react-router-dom";
import styles from "./Offline.module.scss";

function Offline() {
  return (
    <div className={styles.Container}>
      <div className="text-center">
        {/*<h1 className={styles.Status}>Offline</h1>*/}
        <h2 className={styles.Message}>You are offline. Please check your internet connection.</h2>
        {/*<Link className={styles.Link} to={"/"}>&larr; Back to the Dashboard</Link>*/}
      </div>
    </div>
  )
}

export default Offline
