import {useContext, useEffect} from 'react';

import SettingsContext from "../context/settings/settingsContext";

const SettingsCheck = (code) => {
	const settingsContext = useContext(SettingsContext);

	useEffect(() => {
		const list = settingsContext.workspaceSettings;
		const properties = Object.keys(list);

		if (properties.length === 0) {
			settingsContext.loadWorkspaceSettings();
		}

		// eslint-disable-next-line
	}, []);

	const findValueByCode = (code) => {
		const list = settingsContext.workspaceSettings;
		const properties = Object.keys(list);

		for (const property of properties) {
			if (Array.isArray(list[property])) {
				for (const item of list[property]) {
					if (item.code === code) {
						return item.value ?? item.defaultValue;
					}
				}
			}
		}

		return null;
	}

	return findValueByCode(code);
}

export default SettingsCheck;