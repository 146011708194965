import React, { Fragment, useContext, useState } from "react";
import { Card, Dropdown, Nav } from "react-bootstrap";
import { SearchAndSelect } from "../../../../library/objectivebits/botscheduling-ui";
import Loading from "../../../../components/Loading";
import { Avatar } from "@mui/material";
import SettingsCheck from "../../../../utils/SettingsCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IdentifierView from "../../../../components/layout/views/partials/identifier/IdentifierView";
import ResourceManagementLabel from "../../../../utils/ResourceManagementLabel";
import api from "../../../../network/Api";
import { CUSTOMER_RESOURCE_CODE } from "../../../../constants";

import AppointmentCreateContext from "../../../../context/appointmentCreate/appointmentCreateContext";

const AppointmentCreateCustomer = ({appointmentSingular, appointmentPlural, ...props}) => {
	const isRTL = false;

	const [customers, setCustomers] = useState([]);
	const [customerSearchLoading, setCustomerSearchLoading] = useState(false);

	const appointmentCreateContext = useContext(AppointmentCreateContext);

	const customerSingular = ResourceManagementLabel(CUSTOMER_RESOURCE_CODE, 'singular', 'case');
	const customerPlural = ResourceManagementLabel(CUSTOMER_RESOURCE_CODE, 'plural', 'case');

	const customerAvatars = SettingsCheck('customerAvatars');

	const resetCustomer = () => {
		appointmentCreateContext.setCustomer([]);
	}

	// useEffect(() => {
	// 	setCustomers([]);
	// 	{appointmentCreateContext.appointment.customers.map((customer) => (
	// 		api.get(`/core/v2/persons/${customer.identifier}`, {
	// 			validateStatus: function (status) {
	// 				return status === 200 || status === 500;
	// 			}
	// 		}).then(function (response) {
	// 			if (response.status === 200) {
	// 				setCustomers((prevCustomers) => {
	// 					return [
	// 						...prevCustomers,
	// 						{
	// 							'identifier': response.data.identifier,
	// 							'name': response.data.name,
	// 							'avatarUrl': response.data.avatarUrl
	// 						}
	// 					];
	// 				});
	// 			}
	//
	// 			// setPageLoading(false);
	// 		}).catch(function (error) {
	// 			// setPageLoading(false);
	// 		})
	// 	))};
	//
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	const searchHandler = (event) => {
		setCustomerSearchLoading(true);
		api.get(`/core/v2/persons?search=${event.target.value}`,
			{
				validateStatus: function (status) {
					return status === 200;
				}
			})
		.then(function (response) {
			setCustomers(response.data.items.map((customer) => {
				return {
					label: customer.name,
					value: customer.identifier,
					avatarUrl: customer.avatarUrl
				};
			}));

			setCustomerSearchLoading(false);
		})
		.catch(function (error) {
			setCustomerSearchLoading(false);
		});
	}

	const selectedValue = (event) => {
		appointmentCreateContext.setCustomer({
			identifier: event.value,
			name: event.label,
			avatarUrl: event.avatarUrl
		});
	}

	return (
		<>
			<Card.Header className="bg-lightest font-weight-semibold m-0 px-4 py-1">{customerSingular}</Card.Header>
			<div className="py-3 px-4">
				<Loading ready={!appointmentCreateContext.loading}>
					{appointmentCreateContext.appointment.customers.length > 0 ?
						<>
							{appointmentCreateContext.appointment.customers.map((customer, key) => (
								<Card.Title as="div" key={key} className="with-elements m-0">
									{customerAvatars === 'Yes' &&
										<Avatar
											variant="circular"
									        src={customer.avatarUrl}
									        className="bg-primary m-0 mr-2"
										>
											{customer.name.slice(0, 1)}
										</Avatar>
									}
									<div className="font-weight-bold">
										{customer.name}<br/>
										<IdentifierView identifier={customer.identifier}/>
									</div>
									<div className="card-title-elements ml-md-auto">
										<Dropdown variant="primary m-0 p-0" title="" alignRight={isRTL}>
											<Dropdown.Toggle as={Nav.Link} className="hide-arrow m-0 p-0">
												<FontAwesomeIcon icon="ellipsis-vertical" className="p-2 text-secondary"
												                 size="xl"/>
											</Dropdown.Toggle>

											<Dropdown.Menu>
												<Dropdown.Item onClick={() => appointmentCreateContext.resetCustomers()}>
													<FontAwesomeIcon icon="trash" className="text-danger mr-2" />
													Remove {customerSingular}
												</Dropdown.Item>
											</Dropdown.Menu>
										</Dropdown>
									</div>
								</Card.Title>
							))}
						</>
					:
						<div>
							<SearchAndSelect
								searchHandler={searchHandler}
								isLoading={setCustomerSearchLoading}
								options={customers}
								selectedValue={selectedValue}
							/>
						</div>
					}
				</Loading>
			</div>
		</>
	)
}

export default AppointmentCreateCustomer