import React from 'react';
import { Form } from 'react-bootstrap';

const SelectControl = ({form, formControl}) => {
	const {
		register,
		formState: { errors },
	} = form;

	return (
		<Form.Control
			{...register(`${formControl.name}`, formControl.validationRules)}
			name={formControl.name}
			size={formControl.size}
			defaultValue={formControl.value}
			placeholder={formControl.placeholder ?? ""}
			as="select"
			isInvalid={
				errors[`${formControl.name}`] !== undefined
			}
		>
			{Array.isArray(formControl.options) && formControl.options.map(option => (
				<option key={option.key} value={option.key}>{option.label}</option>
			))}
		</Form.Control>
	);
}

export default SelectControl