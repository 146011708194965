import React from 'react';
import { Col, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IndexControl from "./formcontrols/IndexControl";
import TextAreaControl from "./formcontrols/TextAreaControl";
import SelectControl from "./formcontrols/SelectControl";
import CardCheckboxControl from "./formcontrols/CardCheckboxControl";
import SearchableListControl from "./formcontrols/SearchableListControl";
import ChipControl from "./formcontrols/ChipControl";
import PhoneControl from "./formcontrols/PhoneControl";
import TimeControl from "./formcontrols/TimeControl";
import DateControl from "./formcontrols/DateControl";

const FormCreator = ({form, formControls}) => {
	const {
		formState: { errors },
	} = form;

	return (
		<Form>
			<Row>
				{formControls.map(formControl => (
					<Col xs={formControl.col ?? 12} key={formControl.name}>
						<Form.Group className="position-relative" key={formControl.name}>
							<Form.Label>
								{formControl.label}
								{formControl.tooltip &&
									<OverlayTrigger
										trigger="click"
										placement="right"
										overlay={<Popover>
											<Popover.Content>{formControl.tooltip}</Popover.Content>
										</Popover>}>
										<FontAwesomeIcon
											icon={'circle-exclamation'}
											className="ml-1"
											style={{cursor: 'pointer'}}
										/>
									</OverlayTrigger>
								}
							</Form.Label>
							{(formControl.type === "input" || formControl.type === "text") &&
								<>
									<IndexControl form={form} formControl={formControl} />
								</>
							}

							{(formControl.type === "email") &&
								<>
									<IndexControl form={form} formControl={formControl} />
								</>
							}

							{formControl.type === "textarea" &&
								<>
									<TextAreaControl form={form} formControl={formControl} />
								</>
							}

							{formControl.type === "select" &&
								<>
									<SelectControl form={form} formControl={formControl} />
								</>
							}

							{formControl.type === "cardcheckbox" &&
								<CardCheckboxControl form={form} formControl={formControl} />
							}

							{formControl.type === "searchablelist" &&
								<SearchableListControl form={form} formControl={formControl} />
							}

							{formControl.type === "chip" &&
								<ChipControl form={form} formControl={formControl} />
							}

							{formControl.type === "phone" &&
								<PhoneControl form={form} formControl={formControl} />
							}

							{formControl.type === "time" &&
								<div>
									<TimeControl form={form} formControl={formControl} />
								</div>
							}

							{formControl.type === "date" &&
								<div>
									<DateControl form={form} formControl={formControl} />
								</div>
							}

							<Form.Control.Feedback type="invalid">
								{errors[formControl.name] !== undefined ? errors[formControl.name].message : ''}
							</Form.Control.Feedback>
						</Form.Group>
					</Col>
				))}
			</Row>
		</Form>
	);
}

export default FormCreator