import {
	LOAD_WORKSPACE_SETTINGS_SUCCESS,
	LOAD_WORKSPACE_SETTINGS_FAIL,
	LOAD_USER_SETTINGS_SUCCESS,
	LOAD_USER_SETTINGS_FAIL,
	LOAD_LOCATION_SETTINGS_SUCCESS,
	LOAD_LOCATION_SETTINGS_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const settingsReducer = (state, action) => {
	switch (action.type) {
		case LOAD_WORKSPACE_SETTINGS_SUCCESS:
			return {
				...state,
				workspaceSettings: action.payload,
				loading: false,
			}
		case LOAD_WORKSPACE_SETTINGS_FAIL:
			return {
				...state,
				loading: false,
			}
		case LOAD_USER_SETTINGS_SUCCESS:
			return {
				...state,
				userSettings: action.payload,
				loading: false,
			}
		case LOAD_USER_SETTINGS_FAIL:
			return {
				...state,
				loading: false,
			}
		case LOAD_LOCATION_SETTINGS_SUCCESS:
			return {
				...state,
				locationSettings: action.payload,
				loading: false,
			}
		case LOAD_LOCATION_SETTINGS_FAIL:
			return {
				...state,
				loading: false,
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default settingsReducer