import React, { useReducer } from "react";
import api from "../../network/Api";
import CustomObjectsContext from "./customObjectsContext";
import CustomObjectsReducer from "./customObjectsReducer";
import {
	GET_CUSTOM_OBJECTS,
	GET_CUSTOM_OBJECT,
	GET_CUSTOM_OBJECT_DATA_LIST,
	GET_CUSTOM_OBJECT_DATA,
	CREATE_CUSTOM_OBJECT_SUCCESS,
	CREATE_CUSTOM_OBJECT_FAIL,
	UPDATE_CUSTOM_OBJECT_SUCCESS,
	UPDATE_CUSTOM_OBJECT_FAIL,
	DELETE_CUSTOM_OBJECT_SUCCESS,
	DELETE_CUSTOM_OBJECT_FAIL,
	GET_CUSTOM_ATTRIBUTES,
	CREATE_CUSTOM_SUCCESS,
	CREATE_CUSTOM_FAIL,
	CREATE_CUSTOM_ATTRIBUTE_SUCCESS,
	CREATE_CUSTOM_ATTRIBUTE_FAIL,
	UPDATE_RESOURCE_FIELDS_SUCCESS,
	UPDATE_RESOURCE_FIELDS_FAIL,
	DELETE_CUSTOM_SUCCESS,
	DELETE_CUSTOM_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
} from "../types";

const CustomObjectsState = props => {
	const initialState = {
		customObjects: [],
		customObject: null,
		customAttributes: [],
		customObjectDataList: [],
		customObjectData: [],
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(CustomObjectsReducer, initialState);

	const getCustomObjects = () => {
		setLoading();

 		api.get(`/core/v2/custom-objects`, {
			validateStatus: function (status) {
				return status === 200;
			}
		})
		.then(function (response) {
			dispatch({
				type: GET_CUSTOM_OBJECTS,
				payload: response.data
			});
		})
		.catch(function (error) {
			dispatch({
				type: GET_CUSTOM_OBJECTS,
				payload: []
			});
		});
	};

	const getCustomObject = async (resourceCode) => {
		setLoading();

		const res = await api.get(`/core/v2/custom-objects/${resourceCode}`);

		dispatch({
			type: GET_CUSTOM_OBJECT,
			payload: res.data
		});
	};

	const getCustomAttributes = async (resourceCode) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/custom/${resourceCode}/attributes`, config);

			const attributes = res.data.map(attribute => {
				let fieldOptions = [];

				if (attribute.fieldValues !== '') {
					attribute.fieldValues.split(",").map(value => {
						fieldOptions.push({"name": value, "value": value});
					});
				}

				let validationRules = {};

				if (attribute.isRequired) {
					validationRules = {
						required: {
							value: true,
							message: attribute.label + " field is required."
						}
					};
				}

				return {
					label: attribute.label,
					type: attribute.fieldValues.length > 0 ? "select" : "input",
					name: `cf_${attribute.name}`,
					size: "lg",
					default: attribute.default,
					value: null,
					options: fieldOptions,
					validationRules: validationRules
				}
			})

			dispatch({
				type: GET_CUSTOM_ATTRIBUTES,
				payload: attributes
			});
		} catch (err) {
			dispatch({
				type: GET_CUSTOM_ATTRIBUTES,
				payload: []
			});
		}



		//console.log(res.data);

//		setTimeout(() => {
// 		dispatch({
// 			type: GET_CUSTOM_ATTRIBUTES,
// 			payload: r
// 		});
//		}, 2000);
	};

	const getCustomObjectDataList = async (resourceCode) => {
		setLoading();

		const res = await api.get(`/core/v2/custom/${resourceCode}`);

		dispatch({
			type: GET_CUSTOM_OBJECT_DATA_LIST,
			payload: res.data
		});
	};

	const getCustomObjectData = async (resourceCode, customObjectIdentifier) => {
		setLoading();

		const res = await api.get(`/core/v2/custom/${resourceCode}/${customObjectIdentifier}`);

		dispatch({
			type: GET_CUSTOM_OBJECT_DATA,
			payload: res.data
		});
	};

	const createCustomRecord = async (resourceCode, customObject) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/custom/${resourceCode}`, customObject, config);

			dispatch({
				type: CREATE_CUSTOM_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOM_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const createCustomObject = async (customObject) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.post(`/core/v2/custom-objects`, customObject, config);

			dispatch({
				type: CREATE_CUSTOM_OBJECT_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOM_OBJECT_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateCustomObject = async (resourceCode, customObject) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/core/v2/custom-objects/${resourceCode}`, customObject, config);

			dispatch({
				type: UPDATE_CUSTOM_OBJECT_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_CUSTOM_OBJECT_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteCustomObject = async (customObject) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/core/v2/custom-objects/${customObject.identifier}`, config);

			dispatch({
				type: DELETE_CUSTOM_OBJECT_SUCCESS,
				payload: customObject.identifier
			});
		} catch (err) {
			dispatch({
				type: DELETE_CUSTOM_OBJECT_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteCustom = async (resourceCode, customIdentifier) => {
		setLoading();

		api.delete(`/core/v2/custom/${resourceCode}/${customIdentifier}`, {
			validateStatus: function (status) {
				return status === 204;
			}
		})
		.then(function (response) {
			dispatch({
				type: DELETE_CUSTOM_SUCCESS,
				payload: customIdentifier
			});
		})
		.catch(function (error) {
			dispatch({
				type: DELETE_CUSTOM_FAIL,
				payload: error.response.data.message
			});
		});
	};

	const createCustomAttribute = async (resourceCode, customAttributeObject) => {
		setLoading();

		customAttributeObject.type = 'text';
		customAttributeObject.isRequired = parseInt(customAttributeObject.isRequired);
		if (customAttributeObject.default === '') {
			delete customAttributeObject.default;
		}

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/custom-objects/${resourceCode}/fields`, customAttributeObject, config);

			dispatch({
				type: CREATE_CUSTOM_ATTRIBUTE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOM_ATTRIBUTE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateResourceFields = async (resourceCode, resourceIdentifier, fieldData) => {
		setLoading();

		api.patch(`/core/v2/custom/${resourceCode}/${resourceIdentifier}`, fieldData, {
			validateStatus: function (status) {
				return status === 200;
			}
		})
		.then(function (response) {
			dispatch({
				type: UPDATE_RESOURCE_FIELDS_SUCCESS,
				payload: response.data
			});
		})
		.catch(function (error) {
			dispatch({
				type: UPDATE_RESOURCE_FIELDS_FAIL,
				payload: error.response.data.message
			});
		});
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <CustomObjectsContext.Provider
		value={{
			customObjects: state.customObjects,
			customObject: state.customObject,
			customAttributes: state.customAttributes,
			customObjectDataList: state.customObjectDataList,
			customObjectData: state.customObjectData,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getCustomObjects,
			getCustomObject,
			getCustomAttributes,
			getCustomObjectDataList,
			getCustomObjectData,
			createCustomObject,
			updateCustomObject,
			deleteCustomObject,
			createCustomRecord,
			createCustomAttribute,
			updateResourceFields,
			deleteCustom,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</CustomObjectsContext.Provider>
}

export default CustomObjectsState