import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const IdentifierView = ({ identifier, color }) => {
    const [opacity, setOpacity] = useState(0);

    const handleMouseEnter = () => {
        setOpacity(1);
    }

    const handleMouseLeave = () => {
        setOpacity(0);
    }

    const handleClick = () => {
        navigator.clipboard.writeText(identifier);
    }

    const textColor = color === undefined ? 'text-secondary' : `text-${color}`;

    return (
        <code
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleClick}
            className={ opacity === 1 ?
                textColor
            :
                ''
            }
            style={{ cursor: opacity === 1 ? 'pointer' : 'default' }}
        >
            {identifier} <FontAwesomeIcon icon="copy" style={{ opacity}} />
        </code>
    );
}

export default IdentifierView;