import {
	GET_USERS,
	GET_LOGGED_IN_USER,
	GET_LOGGED_IN_USER_FAIL,
	GET_USER_AUTHENTICATION,
	CREATE_USER,
	UPDATE_USER,
	REMOVE_USER,
	REMOVE_PENDING_USER,
	INVITE_USER,
	UPDATE_USER_ROLES,
	SET_LOADING,
	SET_RESET_LOADING
} from "../types";

const usersReducer = (state, action) => {
	switch (action.type) {
		case GET_USERS:
			return {
				...state,
				users: action.payload.users,
				pendingUsers: action.payload.pendingUsers,
				loading: false
			}
		case GET_LOGGED_IN_USER:
			return {
				...state,
				user: action.payload,
				loading: false
			}
		case GET_LOGGED_IN_USER_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case GET_USER_AUTHENTICATION:
			return {
				...state,
				userAuthentication: action.payload,
				loading: false
			}
		case CREATE_USER:
			return {
				...state,
				loading: false
			}
		case UPDATE_USER:
			return {
				...state,
				user: action.payload,
				success: 'User successfully updated',
				loading: false
			}
		case REMOVE_USER:
			return {
				...state,
				users: state.users.filter(
					user => user.userIdentifier !== action.payload
				),
				loading: false
			}
		case REMOVE_PENDING_USER:
			return {
				...state,
				pendingUsers: state.pendingUsers.filter(
					pendingUser => pendingUser.userIdentifier !== action.payload
				),
				loading: false
			}
		case INVITE_USER:
			return {
				...state,
				loading: false
			}
		case UPDATE_USER_ROLES:
			return {
				...state,
				loading: false
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case SET_RESET_LOADING:
			return {
				...state,
				loading: false
			}
		default:
			return state;
	}
}

export default usersReducer