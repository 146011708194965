import {
	GET_PERMISSONS,
	SET_LOADING
} from "../types";

const permissionsReducer = (state, action) => {
	switch (action.type) {
		case GET_PERMISSONS:
			return {
				...state,
				permissions: action.payload,
				loading: false
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default permissionsReducer