import React, { useState } from 'react';
import { maskEmail } from 'react-email-mask'

const MaskMailView = ({ email }) => {
    const [opacity, setOpacity] = useState(0);
    const [emailAddress, setEmailAddress] = useState(maskEmail(email));

    const handleMouseEnter = () => {
        setOpacity(1);
        setEmailAddress(email);
    }

    const handleMouseLeave = () => {
        setOpacity(0);
        setEmailAddress(maskEmail(email));
    }

    const handleClick = () => {
        navigator.clipboard.writeText(email);
    }

    return (
        <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={handleClick} style={{ cursor: opacity === 1 ? 'pointer' : 'default' }}>
             {emailAddress} <i className="ion ion-md-copy" style={{ opacity}}></i>
        </span>
    );
}

export default MaskMailView;