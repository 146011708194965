import {
	LOAD_ATTRIBUTES_SUCCESS,
	LOAD_ATTRIBUTES_FAIL,
	SET_LOADING
} from "../types";

const attributesReducer = (state, action) => {
	switch (action.type) {
		case LOAD_ATTRIBUTES_SUCCESS:
			return {
				...state,
				attributes: action.payload,
				status: 200,
				loading: false,
			}
		case LOAD_ATTRIBUTES_FAIL:
			return {
				...state,
				attributes: [],
				status: action.payload,
				loading: false,
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default attributesReducer