import React, { useReducer } from "react";
import api from "../../network/Api";
import BillingContext from "./billingContext";
import BillingReducer from "./billingReducer";
import {
	GET_BILLING_DETAILS,
	GET_AVAILABLE_PRODUCTS,
	CHANGE_SUBSCRIPTION,
	UPDATE_PAYMENT,
	DELETE_PAYMENT,
	SET_LOADING
} from "../types";

const axios = require('axios').default;

const BillingState = props => {
	const initialState = {
		payments: [],
		transactions: [],
		subscriptions: [],
		availableProducts: [],
		loading: true
	}

	const [ state, dispatch ] = useReducer(BillingReducer, initialState);

	const getDetails = async () => {
		let details = {
			payments: [],
			transactions: [],
			subscriptions: []
		};

		const res = api.get(`/core/v1/billing/payment-methods`).catch(error => error.response);
		const res2 = api.get(`/core/v1/billing/payment-history`).catch(error => error.response);
		const res3 = api.get(`/core/v1/billing/products`).catch(error => error.response);

		await axios.all([res, res2, res3])
		.then(axios.spread((...responses) => {
			if (responses[0] && responses[0].status !== 500) {
				details.payments = responses[0].data;
			}

			if (responses[1] && responses[1].status !== 500) {
				details.transactions = responses[1].data;
			}

			if (responses[2] && responses[2].status !== 500) {
				details.subscriptions = responses[2].data;
			}
		}))
		.catch(errors => {
			//todo do something here?
		});

		dispatch({
			type: GET_BILLING_DETAILS,
			payload: details
		});
	};

	const getAvailableProducts = async () => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200 || status === 500;
			}
		}

		try {
			const res = await api.get(`/core/v1/billing/available-products`, config);

			if (res.status === 500) {
				dispatch({
					type: GET_AVAILABLE_PRODUCTS,
					payload: []
				});
				return;
			}

			dispatch({
				type: GET_AVAILABLE_PRODUCTS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: GET_AVAILABLE_PRODUCTS,
				payload: []
			});
		}
	}

	const changeSubscription = async (data) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			await api.post(`core/v1/billing/subscriptions/change`, data, config);
			await getDetails();
			await getAvailableProducts();
		} catch (err) {

		}

		dispatch({
			type: CHANGE_SUBSCRIPTION,
			payload: null
		});
	}

	const updatePayment = async (card) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/core/v1/billing/payment-methods/card/${card}/default`, config);
			console.log(res);
		} catch (err) {

		}

		dispatch({
			type: UPDATE_PAYMENT,
			payload: card
		});
	}

	const deletePayment = async (card) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			const res = await api.delete(`/core/v1/billing/payment-methods/card/${card}`, config);
		} catch (err) {

		}

		dispatch({
			type: DELETE_PAYMENT,
			payload: card
		});
	}

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <BillingContext.Provider
		value={{
			payments: state.payments,
			availableProducts: state.availableProducts,
			transactions: state.transactions,
			subscriptions: state.subscriptions,
			loading: state.loading,
			getDetails,
			getAvailableProducts,
			changeSubscription,
			updatePayment,
			deletePayment,
			setLoading
		}}
	>
		{props.children}
	</BillingContext.Provider>
}

export default BillingState;