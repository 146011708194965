import React from 'react';

import CheckAccess from "../utils/CheckAccess";

const Access = ({ permission, children }) => {
    const checkAccess = CheckAccess(permission);

    if (checkAccess) {
        return <>{children}</>;
    } else {
        return null;
    }
}

export default Access;