import React, { useEffect } from 'react';
import { Autocomplete, TextField } from '@mui/material';

function SearchAndSelect({searchHandler, isLoading, options, selectedValue}) {
	useEffect(() => {
		// Fetch initial options if needed
	}, []);

	return (
		<Autocomplete
			freeSolo
			onChange={(event, selectedOption) => selectedValue(selectedOption)}
			options={options}
			loading={isLoading}
			renderInput={(params) => (
				<TextField {...params}
					onChange={searchHandler}
					label="Search"
					variant="outlined"
				/>
			)}
			renderOption={(props, option) => (
				<li {...props} key={option.value}>
					{option.label}
				</li>
			)}
		/>
	);
}

export default SearchAndSelect;