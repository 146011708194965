import {
	LOAD_TICKETS_SUCCESS,
	LOAD_TICKETS_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
} from "../types";

const ticketsReducer = (state, action) => {
	switch (action.type) {
		case LOAD_TICKETS_SUCCESS:
			return {
				...state,
				tickets: action.payload,
				status: 200,
				loading: false
			}
		case LOAD_TICKETS_FAIL:
			return {
				...state,
				tickets: [],
				status: action.payload,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default ticketsReducer