import React from 'react';
import { SimpleModal } from "../../../components/layout/BotSchedulingUI";
import Chat from "../../../pages/Chat";


const ChatModel = ({displayModal, handleCloseModal, handleClearConversations, loadResources, formControls, customer, ...props}) => {
	const closeModal = () => {
		handleCloseModal();
	}

	return (
		<SimpleModal header="Chat" show={displayModal} size="xl" onHide={closeModal}>
			<Chat handleClearConversations={handleClearConversations} />
		</SimpleModal>
	);
}

export default ChatModel;