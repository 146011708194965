import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import GroupsContext from "./groupsContext";
import GroupsReducer from "./groupsReducer";
import {
	GET_GROUPS_SUCCESS,
	GET_GROUP_SUCCESS,
	CREATE_GROUP_SUCCESS,
	CREATE_GROUP_FAIL,
	UPDATE_GROUP_SUCCESS,
	UPDATE_GROUP_FAIL,
	DELETE_GROUP,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

import AlertContext from "../../context/alert/alertContext";

const GroupsState = props => {
	const initialState = {
		groups: [],
		groupsNextIdentifier: '',
		groupsSyncIdentifier: '',
		group: null,
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(GroupsReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//Load groups
	const getGroups = async () => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get('/core/v2/groups', config);

			dispatch({
				type: GET_GROUPS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			setAlert('Group List Error', 'Unknown Error', 'danger');

			dispatch({
				type: GET_GROUPS_SUCCESS,
				payload: {"items": [], "syncIdentifier": '', "nextIdentifier": ''}
			});
		}
	}

	const getGroup = async (groupIdentifier) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/core/v2/groups/${groupIdentifier}`, config);

			dispatch({
				type: GET_GROUP_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			//setAlert('Get Group Error', 'Unknown Error', 'danger');

			dispatch({
				type: GET_GROUP_SUCCESS,
				payload: null
			});
		}
	};

	const createGroup = async (group) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/groups`, group, config);

			dispatch({
				type: CREATE_GROUP_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_GROUP_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateGroup = async (groupIdentifier, group) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/core/v2/groups/${groupIdentifier}`, group, config);

			dispatch({
				type: UPDATE_GROUP_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_GROUP_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteGroup = async (group) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/core/v2/groups/${group.identifier}`, config);

			dispatch({
				type: DELETE_GROUP,
				payload: group.identifier
			});
		} catch (err) {
			setAlert('Delete Group Error', 'Unknown Error', 'danger');
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <GroupsContext.Provider
		value={{
			groups: state.groups,
			groupsNextIdentifier: state.groupsNextIdentifier,
			groupsSyncIdentifier: state.groupsSyncIdentifier,
			group: state.group,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getGroups,
			getGroup,
			createGroup,
			updateGroup,
			deleteGroup,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</GroupsContext.Provider>
}

export default GroupsState;