import React, { useReducer } from "react";
import api from "../../network/Api";
import ArticlesContext from "./articlesContext";
import ArticlesReducer from "./articlesReducer";
import {
	GET_ARTICLES,
	SET_LOADING
} from "../types";

const ArticlesState = props => {
	const initialState = {
		articles: [],
		loading: true
	}

	const [ state, dispatch ] = useReducer(ArticlesReducer, initialState);

	const getArticles = async () => {
		setLoading();
		const res = await api.get(`/core/v2/knowledge-base/articles?folderId=19`);

		dispatch({
			type: GET_ARTICLES,
			payload: res.data
			//payload: [{title: 'article 1', body: 'article body 1'}]
		});
	};
	
	const setLoading = () => dispatch({ type: SET_LOADING });

	return <ArticlesContext.Provider
		value={{
			articles: state.articles,
			loading: state.loading,
			getArticles,
			setLoading
		}}
	>
		{props.children}
	</ArticlesContext.Provider>
}

export default ArticlesState;