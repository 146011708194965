import React from 'react';
import {
	FormControl,
	InputLabel,
	MenuItem,
	Select as SelectMUI,
	FormHelperText
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import './Select.scss';

const Select = ({ name, label, title, rules, defaultValue, items }) => {
	const { formState: { errors }, control } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			defaultValue={defaultValue ?? ''}
			rules={rules ?? {}}
			render={({ field, fieldState: { invalid } }) => (
				<>
					{title ? <InputLabel id="demo-select-small-label" className="m-0 p-0">{title}</InputLabel> : ''}
					<SelectMUI
						{...field} // name, value, onChange, onBlur, ref
						fullWidth
						id="demo-select-small-label"
						label={label ?? undefined}
						className="normal"
						size="small"
					>
						{items && Object.keys(items).map((key) => (
							<MenuItem key={key} value={key}>
								{items[key]} {/* Display full country name as label */}
							</MenuItem>
						))}
					</SelectMUI>
					{invalid && (
						<FormHelperText error>{errors[name]?.message}</FormHelperText>
					)}
				</>
			)}
		/>
	);
}

export default Select;
