import {
	GET_ENABLED_TOGGLES,
	GET_APPLICATION_DETAILS,
	SET_LOADING
} from "../types";

export default (state, action) => {
	switch (action.type) {
		case GET_ENABLED_TOGGLES:
			return {
				...state,
				enabledToggles: action.payload,
				loading: false
			}
		case GET_APPLICATION_DETAILS:
			return {
				...state,
				details: action.payload,
				loading: false
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}