import { useContext } from 'react';

import ApplicationContext from "../context/application/applicationContext";

const CheckToggle = (toggleToCheck) => {
	const applicationContext = useContext(ApplicationContext);

	if (applicationContext.enabledToggles === null) {
		return false;
	}

	for (const toggle of applicationContext.enabledToggles) {
		if (toggle.toggle === toggleToCheck) {
			return true;
		}
	}

	return false;
}

export default CheckToggle;