import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    'normal': {
        '& .MuiOutlinedInput-root': {
            paddingTop: '4px',
            paddingBottom: '4px'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.75rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem'
        },
    }
}));

const PositiveNumberInput = (props) => {
    const classes = useStyles();
    const [value, setValue] = useState('1');

    const handleChange = (event) => {
        const inputValue = event.target.value;

        if (/^[1-9][0-9]*$/.test(inputValue)) {
            setValue(inputValue);
        }
    };

    return (
        <TextField
            {...props.field}
            id="outlined-number"
            label={props.label}
            type="number"
            inputProps={{ inputMode: 'numeric', pattern: '^[1-9][0-9]*' }}
            value={value}
            onChange={handleChange}
            className={classes['normal']}
        />
    );
};

export default PositiveNumberInput;