import React from 'react';

const Typography = ({ variant, className, children }) => {
	switch (variant) {
		case "h2":
			return <h2 className={`text-3xl font-bold ${className ?? 'mb-4'}`}>{children}</h2>
		case "h3":
			return <h3 className={`text-2xl font-bold ${className ?? 'mb-4'}`}>{children}</h3>
		case "h4":
			return <h4 className={`text-xl font-bold ${className ?? 'mb-4'}`}>{children}</h4>
		case "h5":
			return <h5 className={`text-lg font-bold ${className ?? 'mb-4'}`}>{children}</h5>
		case "h6":
			return <h6 className={`text-base font-bold ${className ?? 'mb-4'}`}>{children}</h6>
		case "h1":
		default:
			return <h1 className={`text-4xl font-bold ${className ?? 'mb-4'}`}>{children}</h1>
	}
}

export default Typography;