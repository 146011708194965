import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "../typography/Typography";

const ActionableHeading = ({heading, leftButtons, rightButtons}) => {
	return (
		<>
			{heading !== undefined &&
				<Grid container className="mt-2 p-0">
					<Grid xs={12}>
						<Typography variant="h1">{heading}</Typography>
					</Grid>
				</Grid>
			}
			<div className="d-flex justify-content-between align-items-center py-2 mb-2">
				<div>
					{leftButtons}
				</div>
				<div>
					{rightButtons}
				</div>
			</div>
		</>
	);
}

export default ActionableHeading;