import { useEffect } from 'react';

export default function useChat(){
    useEffect(() => {
        const head = document.querySelector("body");

        var c = document.createElement("script");
        c.type = 'text/javascript';
        c.async = true;
        c.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + "help.botscheduling.com/ChatLink.ashx?config=2&id=stlivechat1";
        var s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(c, s);

        head.appendChild(c);

        return () => {
            head.removeChild(c);
        };
    }, []);
};