import React, {Fragment} from "react";
import { Pagination } from "react-bootstrap";

const OBPagination = ({ totalPages, currentPage, onPageChange, totalResultsLabel = '' }) => {
    //const totalPages = Math.ceil(items.length / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const getPageNumbers = () => {
        let startPage, endPage;

        if (totalPages <= 5) {
            startPage = 1;
            endPage = totalPages;
        } else if (currentPage <= 3) {
            startPage = 1;
            endPage = 5;
        } else if (currentPage + 1 >= totalPages) {
            startPage = totalPages - 4;
            endPage = totalPages;
        } else {
            startPage = currentPage - 2;
            endPage = currentPage + 2;
        }

        return pageNumbers.slice(startPage - 1, endPage);
    };

    const renderPaginationLinks = () => {
        const pageItems = getPageNumbers().map((page) => (
            <Pagination.Item
                key={page}
                active={page === currentPage}
                onClick={() => onPageChange(page)}
            >
                {page}
            </Pagination.Item>
        ));

        const ellipsisStart = currentPage > 3 && totalPages > 5;
        const ellipsisEnd = currentPage < totalPages - 2 && totalPages > 5;

        return (
            <>
                {ellipsisStart && <Pagination.Ellipsis />}
                {pageItems}
                {ellipsisEnd && <Pagination.Ellipsis />}
            </>
        );
    };

    return (
        <Fragment>
            {totalResultsLabel !== '' &&
                <div className="float-left mt-1 text-muted small">{totalResultsLabel}</div>
            }
            <Pagination size="sm" className="float-right m-0 p-0">
                <Pagination.Prev
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                />
                {renderPaginationLinks()}
                <Pagination.Next
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                />
            </Pagination>
            <span className="clearfix" />
        </Fragment>
    );
};

export default OBPagination;