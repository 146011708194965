import React from 'react';
import CheckToggle from "../utils/CheckToggle";

const ToggleCheck = ({ name, children }) => {
    const checkToggle = CheckToggle(name);

    if (checkToggle) {
        return <>{children}</>;
    } else {
        return null;
    }
}

export default ToggleCheck