import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import CustomersContext from "./customersContext";
import CustomersReducer from "./customersReducer";
import {
	LOAD_CUSTOMERS_SUCCESS,
	LOAD_MORE_CUSTOMERS_SUCCESS,
	GET_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAIL,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAIL,
	DELETE_CUSTOMER,
	CREATE_CUSTOMER_ADDRESS_SUCCESS,
	CREATE_CUSTOMER_ADDRESS_FAIL,
	UPDATE_CUSTOMER_ADDRESS_SUCCESS,
	UPDATE_CUSTOMER_ADDRESS_FAIL,
	DELETE_CUSTOMER_ADDRESS_SUCCESS,
	DELETE_CUSTOMER_ADDRESS_FAIL,
	CREATE_CUSTOMER_PHONE_SUCCESS,
	CREATE_CUSTOMER_PHONE_FAIL,
	UPDATE_CUSTOMER_PHONE_SUCCESS,
	UPDATE_CUSTOMER_PHONE_FAIL,
	DELETE_CUSTOMER_PHONE_SUCCESS,
	DELETE_CUSTOMER_PHONE_FAIL,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

import AlertContext from "../../context/alert/alertContext";
import axios from "axios";

const CustomersState = props => {
	const initialState = {
		customers: [],
		customersNextIdentifier: '',
		customersSyncIdentifier: '',
		customer: {},
		search: '',
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(CustomersReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//Load customers
	const loadCustomers = async (itemsPerPage, maxNumberOfPages, search = '', searchBy = '') => {
		setLoading();

		let searchUrl = '';
		if (search === '') {
			dispatch({
				type: CLEAR_SEARCH_TERM
			});
		} else {
			searchUrl = `&search=${search}`;
			if (searchBy !== '') {
				searchUrl = `&search=${search}&searchBy=${searchBy}`;
			}

			dispatch({
				type: SEARCH_TERM,
				payload: search
			});
		}

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		let nextIdentifier = '';
		for (let i = 1; i <= maxNumberOfPages; i++) {
			if (i === 1) {
				const res = await api.get(`/core/v2/persons?limit=${itemsPerPage}${searchUrl}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_CUSTOMERS_SUCCESS,
					payload: res.data
				});
			} else if (nextIdentifier !== '') {
				const res = await api.get(`/core/v2/persons?nextIdentifier=${nextIdentifier}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_MORE_CUSTOMERS_SUCCESS,
					payload: res.data
				});
			} else {
				break;
			}
		}
	}

	const getCustomer = async (customerIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		var customer = {};

		try {
			const res = api.get(`/core/v2/persons/${customerIdentifier}`, config);
			const res2 = api.get(`/core/v2/persons/${customerIdentifier}/addresses`, config);
			const res3 = api.get(`/core/v2/persons/${customerIdentifier}/phones`, config);

			await axios.all([res, res2, res3])
				.then(axios.spread((...responses) => {
					customer = responses[0].data;
					customer.addresses = responses[1].data;
					customer.phones = responses[2].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: GET_CUSTOMER_SUCCESS,
				payload: customer
			});
		} catch (err) {
			dispatch({
				type: GET_CUSTOMER_SUCCESS,
				payload: null
			});
		}
	};

	const createCustomer = async (customer) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/persons`, customer, config);

			res.data.addresses = [];
			res.data.phones = [];

			dispatch({
				type: CREATE_CUSTOMER_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOMER_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateCustomer = async (customerIdentifier, customer) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		console.log(customer);

		var updatedCustomer = {};

		try {
			const res = api.patch(`/core/v2/persons/${customerIdentifier}`, customer, config);
			const res2 = api.get(`/core/v2/persons/${customerIdentifier}/addresses`, config);
			const res3 = api.get(`/core/v2/persons/${customerIdentifier}/phones`, config);

			await axios.all([res, res2, res3])
				.then(axios.spread((...responses) => {
					console.log(responses);
					updatedCustomer = responses[0].data;
					updatedCustomer.addresses = responses[1].data;
					updatedCustomer.phones = responses[2].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: UPDATE_CUSTOMER_SUCCESS,
				payload: updatedCustomer
			});
		} catch (err) {
			dispatch({
				type: UPDATE_CUSTOMER_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteCustomer = async (customer) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/core/v2/persons/${customer.identifier}`, config);

			dispatch({
				type: DELETE_CUSTOMER,
				payload: customer.identifier
			});
		} catch (err) {
			setAlert('Delete Customer Error', 'Unknown Error', 'danger');
		}
	};

	const createCustomerAddress = async (customerIdentifier, address) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/persons/${customerIdentifier}/addresses`, address, config);

			dispatch({
				type: CREATE_CUSTOMER_ADDRESS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOMER_ADDRESS_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteCustomerAddress = async (customer, address) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/core/v2/persons/${customer.identifier}/addresses/${address.identifier}`, config);

			dispatch({
				type: DELETE_CUSTOMER_ADDRESS_SUCCESS,
				payload: customer.identifier
			});
		} catch (err) {
			dispatch({
				type: DELETE_CUSTOMER_ADDRESS_FAIL,
				payload: 'Failed to remove customer address.'
			});
		}
	};

	const createCustomerPhone = async (customerIdentifier, phone) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/persons/${customerIdentifier}/phones`, phone, config);

			dispatch({
				type: CREATE_CUSTOMER_PHONE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_CUSTOMER_PHONE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteCustomerPhone = async (customer, phone) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/core/v2/persons/${customer.identifier}/phones/${phone.identifier}`, config);

			dispatch({
				type: DELETE_CUSTOMER_PHONE_SUCCESS,
				payload: customer.identifier
			});
		} catch (err) {
			dispatch({
				type: DELETE_CUSTOMER_PHONE_FAIL,
				payload: 'Failed to remove customer phone.'
			});
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });
	
	const setLoading = () => dispatch({ type: SET_LOADING });

	const clearSearch = () => dispatch({ type: CLEAR_SEARCH_TERM });

	return <CustomersContext.Provider
		value={{
			customers: state.customers,
			customersNextIdentifier: state.customersNextIdentifier,
			customersSyncIdentifier: state.customersSyncIdentifier,
			customer: state.customer,
			success: state.success,
			error: state.error,
			loading: state.loading,
			loadCustomers,
			getCustomer,
			createCustomer,
			updateCustomer,
			deleteCustomer,
			createCustomerAddress,
			createCustomerPhone,
			deleteCustomerAddress,
			deleteCustomerPhone,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</CustomersContext.Provider>
}

export default CustomersState;