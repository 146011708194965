import {
	LOAD_ACCESS_DETAILS,
	LOAD_PERMISSIONS_BY_ROLE,
	CREATE_ROLE,
	CREATE_ROLE_FAILED,
	UPDATE_ROLE_SUCCESS,
	UPDATE_ROLE_FAIL,
	UPLOAD_PERMISSIONS_FOR_ROLE,
	DELETE_ROLE,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const accessReducer = (state, action) => {
	switch (action.type) {
		case LOAD_ACCESS_DETAILS:
			return {
				...state,
				roles: action.payload.roles,
				permissions: action.payload.permissions,
				loading: false
			}
		case LOAD_PERMISSIONS_BY_ROLE:
			return {
				...state,
				rolePermissions: action.payload,
				loading: false
			}
		case UPLOAD_PERMISSIONS_FOR_ROLE:
			return {
				...state,
				loading: false
			}
		case CREATE_ROLE:
			return {
				...state,
				roles: [...state.roles, action.payload],
				success: 'Role successfully created',
				loading: false
			}
		case CREATE_ROLE_FAILED:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_ROLE_SUCCESS:
			return {
				...state,
				roles: state.roles.map(role =>
					role.identifier === action.payload.identifier ? action.payload : role
				),
				success: 'Role successfully updated',
				loading: false
			}
		case UPDATE_ROLE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_ROLE:
			return {
				...state,
				roles: state.roles.filter(
					role => role.identifier !== action.payload
				),
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default accessReducer