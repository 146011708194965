import React, { Fragment, useContext, useEffect, useState } from "react";
import {Button, Card, Form} from "react-bootstrap";
import {SearchAndSelect, SelectSearchable} from "../../../../library/objectivebits/botscheduling-ui";
import api from "../../../../network/Api";
import Loading from "../../../../components/Loading";
import {Avatar} from "@mui/material";
import SettingsCheck from "../../../../utils/SettingsCheck";
import Grid from "@mui/material/Unstable_Grid2";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IdentifierView from "../../../../components/layout/views/partials/identifier/IdentifierView";
import axios from "axios";
import AppointmentCreateTimeBlockLocation from "./partial/AppointmentCreateTimeBlockLocation";
import AppointmentCreateTimeBlockService from "./partial/AppointmentCreateTimeBlockService";
import AppointmentCreateTimeBlockDateTime from "./partial/AppointmentCreateTimeBlockDateTime";

import AppointmentCreateContext from "../../../../context/appointmentCreate/appointmentCreateContext";
import { DateCalendar } from "@mui/x-date-pickers";
import { format } from "date-fns";

const AppointmentCreateTimeBlock = ({ instanceKey, appointment, handleBack, handleNext, handleLocation, handleServices, handleStartsOn, handleEndsOn }) => {
	const [instances, setInstances] = useState([]);
	const [loading, setLoading] = useState(true);

	const { state, setStartsOn } = useContext(AppointmentCreateContext);

	const handleDateChange = (newDate) => {
		const prevAppointmentInstance = state.appointment.instances[instanceKey];
		const { startsOn } = prevAppointmentInstance;

		let newStartsOn;
		if (startsOn) {
			const timePart = startsOn.split(' ')[1];
			newStartsOn = `${newDate} ${timePart}`;
		} else {
			newStartsOn = `${newDate} 00:00:00`;
		}

		setStartsOn(instanceKey, newStartsOn);
	};

	return (
		<>
			<Card.Header className="bg-lightest font-weight-semibold m-0 px-4 py-1">Time / Date</Card.Header>
			<Card.Body className="m-0 p-0">
				<Grid container="true" columnSpacing={0}>
					<Grid item="true" xs={6} sm={4}
					      display="flex"
					      justifyContent="center"
					      alignItems="center"
					>
						<DateCalendar className="m-0 p-0 mt-4" onChange={(date) => handleDateChange(date)} />
					</Grid>
					<Grid item="true" xs sm className="m-0 p-4 bg-lighter"
					      justifyContent="center"
					      alignItems="baseline"
					>
						{loading ? <Loading /> :
							<>
								{/*{slots.length > 0 ?*/}
								{/*	<>*/}
								{/*		{slots.map((slot) => (*/}
								{/*			<Fragment key={slot.identifier}>*/}
								{/*				<Button variant={`${format(new Date(slot.startsOn), "h:mm a") === format(new Date(startsOn), "h:mm a") ? 'primary' : 'outline-primary'}`} className="m-2" onClick={() => setStartsOn(slot.startsOn)}>{format(new Date(slot.startsOn), "h:mm a")}</Button>*/}
								{/*			</Fragment>*/}
								{/*		))}*/}
								{/*	</>*/}
								{/*	:*/}
								{/*	<div className="text-center">*/}
								{/*		<h4>No slots found for provided criteria</h4>*/}
								{/*		<p>Please change the search to retrieve available slots.</p>*/}
								{/*	</div>*/}
								{/*}*/}
							</>
						}
					</Grid>
				</Grid>
			</Card.Body>

			{/*<div className="py-3 px-4">*/}
			{/*	<SearchAndSelect*/}
			{/*		searchHandler={searchHandler}*/}
			{/*		isLoading={isLoading}*/}
			{/*		options={options}*/}
			{/*		selectedValue={selectedValue}*/}
			{/*	/>*/}
			{/*</div>*/}
			{/*<div>*/}
			{/*	<h1 className="text-xlarge m-0 mr-2">*/}
			{/*		<Fragment>Appointment for </Fragment>*/}
			{/*		/!*{appointment.customers.map((customer, index) => (*!/*/}
			{/*		/!*	<Fragment key={index}>*!/*/}
			{/*		/!*		{index > 0 && ", "}*!/*/}
			{/*		/!*		{customer.name}*!/*/}
			{/*		/!*	</Fragment>*!/*/}
			{/*		/!*))}*!/*/}
			{/*	</h1>*/}
			{/*	<div className="mb-0">*/}
			{/*		/!*<IdentifierView identifier={appointment.identifier} />*!/*/}
			{/*	</div>*/}
			{/*</div>*/}
			{/*<div className="card-title-elements ml-md-auto">*/}
			{/*</div>*/}
			{/*<AppointmentCreateTimeBlockLocation instance={appointment.instances[0]} handleLocation={handleLocation} />*/}
			{/*<AppointmentCreateTimeBlockService instance={appointment.instances[0]} handleServices={handleServices} />*/}

			{/*{appointment.instances[0].services && appointment.instances[0].services.length > 0 &&*/}
			{/*	<AppointmentCreateTimeBlockDateTime instance={appointment.instances[0]} handleStartsOn={handleStartsOn} handleEndsOn={handleEndsOn} />*/}
			{/*}*/}
			{/*<Card.Body>*/}
			{/*	<Form>*/}
			{/*		<Form.Group>*/}
			{/*			<Form.Label >Location</Form.Label>*/}
			{/*			{appointment.instances[0].location ?*/}
			{/*				<>*/}
			{/*					<h4>{appointment.instances[0].location.name}</h4>*/}
			{/*					<IdentifierView identifier={appointment.instances[0].location.identifier} />*/}
			{/*				</>*/}
			{/*			:*/}
			{/*				<SearchAndSelect searchHandler={searchHandler} isLoading={isLoading} options={options} selectedValue={selectedValue} />*/}
			{/*			}*/}
			{/*		</Form.Group>*/}
			{/*		<hr className="m-0" />*/}
			{/*		<Form.Group>*/}
			{/*			<Form.Label>Serivce</Form.Label>*/}
			{/*			<Form.Control type="text" placeholder="Location" />*/}
			{/*		</Form.Group>*/}
			{/*		<Form.Group>*/}
			{/*			<Form.Label>Resources</Form.Label>*/}
			{/*			<Form.Control type="text" placeholder="Location" />*/}
			{/*		</Form.Group>*/}
			{/*		<Form.Group>*/}
			{/*			<Form.Label>Date / Time</Form.Label>*/}
			{/*			<Form.Control type="text" placeholder="Location" />*/}
			{/*		</Form.Group>*/}
			{/*	</Form>*/}
			{/*</Card.Body>*/}
			{/*<Card.Footer className="m-0 py-2 p-0">*/}
			{/*	<Grid container={true} columnSpacing={0}>*/}
			{/*		<Grid item="true" xs={6}>*/}
			{/*			<Button variant="link text-uppercase" onClick={handleBack}>*/}
			{/*				<><FontAwesomeIcon icon="caret-left"/> Select Customer</>*/}
			{/*			</Button>*/}
			{/*		</Grid>*/}
			{/*		<Grid item="true" xs={6} className="text-right">*/}
			{/*			<Button variant="link text-uppercase" onClick={handleNext}>*/}
			{/*				<>Finalize Appointment <FontAwesomeIcon icon="caret-right"/></>*/}
			{/*			</Button>*/}
			{/*		</Grid>*/}
			{/*	</Grid>*/}
			{/*</Card.Footer>*/}
		</>
	)
}

export default AppointmentCreateTimeBlock