import {
	GET_GROUPS_SUCCESS,
	GET_GROUP_SUCCESS,
	CREATE_GROUP_SUCCESS,
	CREATE_GROUP_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	UPDATE_GROUP_SUCCESS,
	UPDATE_GROUP_FAIL,
	DELETE_GROUP,
	SET_LOADING
} from "../types";

const groupsReducer = (state, action) => {
	switch (action.type) {
		case GET_GROUPS_SUCCESS:
			return {
				...state,
				groups: action.payload.items,
				groupsSyncIdentifier: action.payload.syncIdentifier,
				groupsNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_GROUP_SUCCESS:
			return {
				...state,
				group: action.payload,
				loading: false
			}
		case CREATE_GROUP_SUCCESS:
			return {
				...state,
				group: action.payload,
				groups: [...state.groups, action.payload],
				success: 'Group successfully created',
				loading: false
			}
		case CREATE_GROUP_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_GROUP_SUCCESS:
			return {
				...state,
				group: action.payload,
				groups: state.groups.map(group =>
					group.identifier === action.payload.identifier ? action.payload : group
				),
				success: 'Group successfully updated',
				loading: false
			}
		case UPDATE_GROUP_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_GROUP:
			return {
				...state,
				groups: state.groups.filter(
					group => group.identifier !== action.payload
				),
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default groupsReducer