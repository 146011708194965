import React, { useState, useEffect } from 'react'
import Router from './shared/Router'
import { useForm, FormProvider } from "react-hook-form";
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import Offline from "./shared/Offline";
//import socket from "./socket";

import './vendor/styles/bootstrap.scss'
import './vendor/styles/appwork.scss'
import './vendor/styles/theme-corporate.scss'
import './vendor/styles/colors.scss'
import './vendor/styles/uikit.scss'
import './App.scss'

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import LoadingState from "./context/loading/LoadingState";
import AuthState from "./context/auth/AuthState";
import AlertState from "./context/alert/AlertState";
import TicketsState from "./context/workspaces/TicketsState";
import BillingState from "./context/workspaces/BillingState";
import ApplicationState from "./context/application/ApplicationState";
import PermissionsState from "./context/permissions/PermissionsState";
import AccessState from "./context/permissions/AccessState";
import UsersState from "./context/users/UsersState";
import WorkspacesState from "./context/workspaces/WorkspacesState";
import LocationsState from "./context/locations/LocationsState";
import AppointmentsState from "./context/appointments/AppointmentsState";
import AppointmentCreateState from "./context/appointmentCreate/AppointmentCreateState";
import CustomersState from "./context/customers/CustomersState";
import ServicesState from "./context/services/ServicesState";
import SchedulesState from "./context/schedules/SchedulesState";
import StatusesState from "./context/statuses/StatusesState";
import CustomObjectsState from "./context/custom/CustomObjectsState";
import ResourcesState from "./context/resources/ResourcesState";
import GroupsState from "./context/groups/GroupsState";
import ModalState from "./context/layout/ModalState";
import ModalBuilderContentFormState from "./context/layout/ModalBuilderContentFormState";
import Alert from "./components/alert/Alert";
import ArticlesState from "./context/articles/ArticlesState";
import ActionState from "./context/action/ActionState";
import SettingsState  from "./context/settings/SettingsState";
import ChatState from "./context/chat/ChatState";
import AttributesState from "./context/attributes/AttributesState";

import { library } from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import * as Brands from '@fortawesome/free-brands-svg-icons';

const iconList = Object.keys(Icons)
.filter((key) => key !== 'fas' && key !== 'prefix')
.map((icon) => Icons[icon]);

const brandIconList = Object.keys(Brands)
.filter((key) => key !== 'fab' && key !== 'prefix')
.map((icon) => Brands[icon]);

library.add(...iconList, ...brandIconList);

Bugsnag.start({
  apiKey: process.env.NODE_ENV === 'production' ? '3e4a1fe35a2f03ecfcaaf6dbb0c49dec' : 'Your API Key',
  plugins: [new BugsnagPluginReact()],
  enabledReleaseStages: ['production']
});

const ErrorBoundary = Bugsnag.getPlugin('react')
.createErrorBoundary(React)

const App = () => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const form = useForm()

  useEffect(() => {
    // Connect to the socket server
    // socket.connect();
    //
    // console.log("foo");
    //
    // // Set up event listeners
    // socket.on("connect", () => {
    //   console.log("Connected to the socket server");
    // });
    //
    // socket.on("message", (data) => {
    //   console.log("Received message:", data);
    // });
    //
    // socket.on("status", (data) => {
    //   console.log("Received status:", data);
    // });

    function onlineHandler() {
      setIsOnline(true);
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);


    return () => {
      //socket.disconnect();

      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  return (
    <ErrorBoundary>
    <LoadingState>
      <AuthState>
        <AlertState>
          <TicketsState>
            <BillingState>
              <ApplicationState>
                <PermissionsState>
                  <AccessState>
                    <UsersState>
                      <WorkspacesState>
                        <LocationsState>
                          <AppointmentsState>
                            <AppointmentCreateState>
                              <CustomersState>
                                <ServicesState>
                                  <SchedulesState>
                                    <StatusesState>
                                      <CustomObjectsState>
                                        <ResourcesState>
                                          <GroupsState>
                                            <ModalState>
                                              <ModalBuilderContentFormState>
                                                <ArticlesState>
                                                  <ActionState>
                                                    <SettingsState>
                                                      <AttributesState>
                                                        <ChatState>
                                                          <FormProvider {...form}>
                                                            <Alert />
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                              {isOnline ? (
                                                                  <Router />
                                                              ) : (
                                                                  <Offline />
                                                              )}
                                                            </LocalizationProvider>
                                                          </FormProvider>
                                                        </ChatState>
                                                      </AttributesState>
                                                    </SettingsState>
                                                  </ActionState>
                                                </ArticlesState>
                                              </ModalBuilderContentFormState>
                                            </ModalState>
                                          </GroupsState>
                                        </ResourcesState>
                                      </CustomObjectsState>
                                    </StatusesState>
                                  </SchedulesState>
                                </ServicesState>
                              </CustomersState>
                            </AppointmentCreateState>
                          </AppointmentsState>
                        </LocationsState>
                      </WorkspacesState>
                    </UsersState>
                  </AccessState>
                </PermissionsState>
              </ApplicationState>
            </BillingState>
          </TicketsState>
        </AlertState>
      </AuthState>
    </LoadingState>
    </ErrorBoundary>
  )
}

export default App