import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import StatusesContext from "./statusesContext";
import StatusesReducer from "./statusesReducer";
import {
	LOAD_STATUSES_SUCCESS,
	LOAD_STATUSES_FAIL,
	GET_STATUS_SUCCESS,
	CREATE_STATUS_SUCCESS,
	CREATE_STATUS_FAIL,
	UPDATE_STATUS_SUCCESS,
	UPDATE_STATUS_FAIL,
	DELETE_STATUS,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

import AlertContext from "../alert/alertContext";

const StatusesState = props => {
	const initialState = {
		statuses: [],
		status: {},
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(StatusesReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//Load statuses
	const loadStatuses = async () => {
		setLoading();

		api.get(`/appointments/v2/statuses`,
			{
				validateStatus: function (status) {
					return status === 200 || status === 500;
				}
		}).then(function (response) {
			if (response.status === 500) {
				dispatch({
					type: LOAD_STATUSES_FAIL,
					payload: []
				});
			} else {
				dispatch({
					type: LOAD_STATUSES_SUCCESS,
					payload: response.data
				});
			}
		}).catch(function (error) {
			dispatch({
				type: LOAD_STATUSES_FAIL,
				payload: []
			});
		});
	}

	const getStatus = async (statusIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/appointments/v2/statuses/${statusIdentifier}`, config);

			dispatch({
				type: GET_STATUS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: GET_STATUS_SUCCESS,
				payload: null
			});
		}
	};

	const createStatus = async (status) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/appointments/v2/statuses`, status, config);

			dispatch({
				type: CREATE_STATUS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_STATUS_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateStatus = async (statusIdentifier, status) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/appointments/v2/statuses/${statusIdentifier}`, status, config);

			dispatch({
				type: UPDATE_STATUS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_STATUS_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteStatus = async (status) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/appointments/v2/statuses/${status.identifier}`, config);

			dispatch({
				type: DELETE_STATUS,
				payload: status.identifier
			});
		} catch (err) {
			setAlert('Delete Status Error', 'Unknown Error', 'danger');
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <StatusesContext.Provider
		value={{
			statuses: state.statuses,
			status: state.status,
			success: state.success,
			error: state.error,
			loading: state.loading,
			loadStatuses,
			getStatus,
			createStatus,
			updateStatus,
			deleteStatus,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</StatusesContext.Provider>
}

export default StatusesState;