import React, { useContext, useEffect } from 'react';
import AlertContext from '../../context/alert/alertContext';

import { ToastContainer, toast } from 'react-toastify';
import '../../vendor/libs/react-toastify/react-toastify.scss';

const Alert = (props) => {
	const alertContext  = useContext(AlertContext);

	const Msg = ({ closeToast, toastProps, title, message, type}) => (
		<div className={`bs4-toast toast show bg-${type}`} key={title} style={{cursor:"auto"}}>
			<div className="toast-header">
				{/*<img src={process.env.PUBLIC_URL + '/' + toast.avatar} className="d-block ui-w-20 rounded mr-2" alt="Avatar" />*/}
				<div className="mr-auto font-weight-bold">{title}</div>
				<div className="d-block small">now</div>
				<button type="button" style={{cursor:"pointer"}} className="d-block close mb-1 ml-2" onClick={closeToast}>&times;</button>
			</div>
			<div className="toast-body">
				{message}
			</div>
		</div>
	);

	useEffect(() => {
		for (const alert of alertContext.alerts) {
			toast(<Msg title={alert.title} message={alert.message} type={alert.type} />, {
				position: "top-right",
				autoClose: 5000,
				hideProgressBar: false,
				closeOnClick: false,
				pauseOnHover: true,
				draggable: false,
				progress: undefined,
			})

			alertContext.removeAlert(alert)
		}
	}, [alertContext]);

	return (
		<ToastContainer
			position="top-right"
			autoClose={5000}
			hideProgressBar={false}
			newestOnTop={false}
			closeOnClick={false}
			rtl={false}
			pauseOnFocusLoss
			pauseOnHover
		/>
	);
}

export default Alert;