import React, { useReducer } from "react";
import ActionContext from "./actionContext";
import actionReducer from "./actionReducer";
import {
	GET_ACTION,
	SET_LOADING
} from "../types";
import api from "../../network/Api";

const ActionState = props => {
	const initialState = {
		action: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(actionReducer, initialState);

	//getAction
	const getAction = (token) => {
		api.get(`/core/v2/action/${token}`, {
			validateStatus: function (status) {
				return status === 200;
			}
		}).then(function (response) {
			const action = response.data;

			api.get(`/core/v2/users/${action.userIdentifier}/authentication`, {
				validateStatus: function (status) {
					return status === 200;
				}
			}).then(function (response) {
				dispatch({
					type: GET_ACTION,
					payload: action
				});
			}).catch(function (error) {
				dispatch({
					type: GET_ACTION,
					payload: action
				});
			});
		}).catch(function (error) {
			dispatch({
				type: GET_ACTION,
				payload: null
			});
		});
	}

	const setLoading = () => dispatch({ type: SET_LOADING });

	return (
		<ActionContext.Provider
			value={{
				action: state,
				loading: state.loading,
				getAction,
				setLoading
			}}>

			{ props.children }
		</ActionContext.Provider>
	);
};

export default ActionState;