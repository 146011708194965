import React, { useState } from "react";
import { Button } from "../BotSchedulingUI";
import { Card, Col, Nav, Row } from "react-bootstrap";
import IdentifierView from "../views/partials/identifier/IdentifierView";

const TabbedHeader = ({heading, action, items, defaultActiveKey, variant, handleTabClick, children}) => {
	const [currentKey, setCurrentKey] = useState(defaultActiveKey ?? null);

	return (
		<Card>
			<span></span>
			<Card.Header className="card bg-primary border-primary">
				<Row className="align-items-center">
					<Col xs={6}>
						{heading ?? ''}
					</Col>
					<Col xs={6} className="text-right">
						{action ?? ''}
					</Col>
				</Row>
				{Array.isArray(items) &&
					<Nav variant="tabs" defaultActiveKey={`#${currentKey}`} className="pl-2">
						{items.map((item, index) => (
							<Nav.Item>
								<Nav.Link
									href={`#${item.name}`}
									onClick={() => {
										handleTabClick(item.key);
										setCurrentKey(item.name);
									}}
									className={`font-weight-bold ${currentKey === item.name ? 'text-primary' : 'text-white'}`}
								>
									{item.name}
								</Nav.Link>
							</Nav.Item>
						))}
					</Nav>
				}
			</Card.Header>
			<Card.Body className="m-0 p-0">
				{children}
			</Card.Body>
		</Card>
	);
}

export default TabbedHeader;