import React, { useContext, useEffect } from 'react'
import { Route, Redirect, useLocation } from 'react-router-dom'

import AuthContext from '../context/auth/authContext'

const NotLoggedInRoute = ({ ...rest }) => {
    const authContext = useContext(AuthContext);

    const { loading, authenticationToken, loadAuthentication } = authContext;

    const search = useLocation().search;
    const redirect = new URLSearchParams(search).get('redirect');

    useEffect(() => {
        const token = localStorage.getItem('token');
        const workspaceIdentifier = localStorage.getItem('workspaceIdentifier');

        loadAuthentication(token, workspaceIdentifier);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <div />;
    }

    if (authenticationToken === null) {
         return <Route {...rest} />
    }

    if (redirect !== null) {
        return <Redirect to={redirect} />
    }

    return <Redirect to='/' />
}

export default NotLoggedInRoute