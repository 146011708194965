import {
	LOAD_SCHEDULES_BY_RESOURCE_SUCCESS,
	LOAD_SCHEDULES_BY_RESOURCE_FAIL,

	LOAD_SCHEDULES_SUCCESS,
	LOAD_MORE_SCHEDULES_SUCCESS,
	GET_SCHEDULE_SUCCESS,
	CREATE_SCHEDULE_SUCCESS,
	CREATE_SCHEDULE_FAIL,
	UPDATE_SCHEDULE_SUCCESS,
	UPDATE_SCHEDULE_FAIL,
	DELETE_SCHEDULE,
	SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const schedulesReducer = (state, action) => {
	switch (action.type) {
		case LOAD_SCHEDULES_BY_RESOURCE_SUCCESS:
			return {
				...state,
				schedules: { ...state.schedules, ...action.payload },
				loading: false,
			}
		case LOAD_SCHEDULES_BY_RESOURCE_FAIL:
			return {
				...state,
				schedules: null,
				loading: false,
			}

		case SEARCH_TERM:
			return {
				...state,
				search: action.payload,
			}
		case LOAD_SCHEDULES_SUCCESS:
			return {
				...state,
				schedules: action.payload.items,
				schedulesSyncIdentifier: action.payload.syncIdentifier,
				schedulesNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case LOAD_MORE_SCHEDULES_SUCCESS:
			return {
				...state,
				schedules: [...state.schedules.concat(action.payload.items)],
				schedulesSyncIdentifier: action.payload.syncIdentifier,
				schedulesNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_SCHEDULE_SUCCESS:
			return {
				...state,
				schedule: action.payload,
				loading: false
			}
		case CREATE_SCHEDULE_SUCCESS:
			let bar;
			if (state.schedules[action.payload.assignedToIdentifier]) {
				bar = state.schedules[action.payload.assignedToIdentifier].push(action.payload);
			} else {
				bar = state.schedules[action.payload.assignedToIdentifier] = [action.payload];
			}

			return {
				...state,
				schedules: [...state.schedules, bar],
				success: 'Schedule successfully created',
				loading: false
			}
		case CREATE_SCHEDULE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_SCHEDULE_SUCCESS:
			return {
				...state,
				schedule: action.payload,
				schedules: state.schedules.map(schedule =>
					schedule.identifier === action.payload.identifier ? action.payload : schedule
				),
				success: 'Schedule successfully updated',
				loading: false
			}
		case UPDATE_SCHEDULE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_SCHEDULE:
			return {
				...state,
				schedules: state.schedules.filter(
					schedule => schedule.identifier !== action.payload
				),
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default schedulesReducer