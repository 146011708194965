import React from "react";
import { Modal } from "react-bootstrap";

const SimpleModalHeader = ({as, children}) => {
	return (
		<Modal.Header closeButton>
			<Modal.Title as={as ?? "h4"}>
				{children}
			</Modal.Title>
		</Modal.Header>
	);
}

export default SimpleModalHeader;