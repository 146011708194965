import {
	SET_INITIAL_PAGE_LOADING,
	SET_DATA_LOADING,
	SET_PAGE_LOADING
} from "../types";

const loadingReducer = (state, action) => {
	switch (action.type) {
		case SET_INITIAL_PAGE_LOADING:
			return {
				...state,
				initialPageLoading: action.payload,
			}
		case SET_DATA_LOADING:
			return {
				...state,
				dataLoading: action.payload,
			}
		case SET_PAGE_LOADING:
			return {
				...state,
				pageLoading: action.payload,
			}
		default:
			return state;
	}
}

export default loadingReducer