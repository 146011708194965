import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Badge } from "react-bootstrap";
import useChat from "../../hooks/useChat"

import ApplicationContext from "../../context/application/applicationContext";

const LayoutFooter = (props) => {
    const year = new Date().getFullYear();
    useChat();

    const applicationContext = useContext(ApplicationContext);

    useEffect(() => {
        if (props.currentWorkspace) {
            applicationContext.getDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.currentWorkspace]);

    const { details } = applicationContext;

    return (
      <nav className={`layout-footer footer bg-footer-theme`}>
          <div className="container-fluid container-p-x pb-3">
              <div className="row">
                  <div className="col-12 col-lg-6 col-xl-6 text-center text-lg-left">
                      <Link to="/updates" className="footer-link pt-3 pl-3">Updates</Link>
                      <a href="https://botscheduling.statuspage.io" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3">
                          <Badge variant={`${!applicationContext.loading && details?.status?.length > 0 ? "danger" : "success"} badge-dot`} className="mr-1" />
                          Status
                      </a>
                      <a href="https://botscheduling.com/docs" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3">Docs</a>
                      <a href="https://botscheduling.com/terms" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3 d-none d-lg-inline">Terms</a>
                      <a href="https://botscheduling.com/privacy" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3 d-none d-lg-inline">Privacy</a>
                  </div>
                  <div className="col-12 text-center d-lg-none d-xl-none">
                      <a href="https://botscheduling.com/terms" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3">Terms</a>
                      <a href="https://botscheduling.com/privacy" rel="noreferrer" target="_blank" className="footer-link pt-3 pl-3">Privacy</a>
                  </div>
                  <div className="col-12 col-lg-6 col-xl-6 text-center text-lg-right">
                      <div className="pt-3 ml-4">&copy; {year} BotScheduling. All rights reserved.</div>
                  </div>
              </div>
          </div>
      </nav>
    )
}

export default LayoutFooter;