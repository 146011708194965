import React, { useReducer, useContext } from "react";
import api from "../../network/Api";
import LocationsContext from "./locationsContext";
import LocationsReducer from "./locationsReducer";
import {
	GET_LOCATIONS_SUCCESS,
	GET_MORE_LOCATIONS_SUCCESS,
	GET_LOCATION_SUCCESS,
	CREATE_LOCATION_SUCCESS,
	CREATE_LOCATION_FAIL,
	UPDATE_LOCATION_SUCCESS,
	UPDATE_LOCATION_FAIL,
	DELETE_LOCATION,
	CREATE_LOCATION_ADDRESS_SUCCESS,
	CREATE_LOCATION_ADDRESS_FAIL,
	CREATE_LOCATION_PHONE_SUCCESS,
	CREATE_LOCATION_PHONE_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
	CLEAR_SEARCH_TERM,
	SEARCH_TERM
} from "../types";

import AlertContext from "../../context/alert/alertContext";
import axios from "axios";

const LocationsState = props => {
	const initialState = {
		locations: [],
		locationsNextIdentifier: '',
		locationsSyncIdentifier: '',
		location: {},
		search: '',
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(LocationsReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//Load locations
	const getLocations = async (itemsPerPage, maxNumberOfPages, search = '', searchBy = '') => {
		setLoading();

		let searchUrl = '';
		if (search === '') {
			dispatch({
				type: CLEAR_SEARCH_TERM
			});
		} else {
			searchUrl = `&search=${search}`;
			if (searchBy !== '') {
				searchUrl = `&search=${search}&searchBy=${searchBy}`;
			}

			dispatch({
				type: SEARCH_TERM,
				payload: search
			});
		}

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		let nextIdentifier = '';
		for (let i = 1; i <= maxNumberOfPages; i++) {
			if (i === 1) {
				const res = await api.get(`/appointments/v2/locations?limit=${itemsPerPage}${searchUrl}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: GET_LOCATIONS_SUCCESS,
					payload: res.data
				});
			} else if (nextIdentifier !== '') {
				const res = await api.get(`/appointments/v2/locations?nextIdentifier=${nextIdentifier}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: GET_MORE_LOCATIONS_SUCCESS,
					payload: res.data
				});
			} else {
				break;
			}
		}
	}

	const getLocation = async (locationIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		var location = {};

		try {
			const res = api.get(`/appointments/v2/locations/${locationIdentifier}`, config);
			const res2 = api.get(`/core/v2/locations/${locationIdentifier}/addresses`, config);
			const res3 = api.get(`/core/v2/locations/${locationIdentifier}/phones`, config);

			await axios.all([res, res2, res3])
				.then(axios.spread((...responses) => {
					location = responses[0].data;
					location.addresses = responses[1].data;
					location.phones = responses[2].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: GET_LOCATION_SUCCESS,
				payload: location
			});
		} catch (err) {
			//setAlert('Get Location Error', 'Unknown Error', 'danger');

			dispatch({
				type: GET_LOCATION_SUCCESS,
				payload: null
			});
		}
	};

	const createLocation = async (location) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/appointments/v2/locations`, location, config);

			dispatch({
				type: CREATE_LOCATION_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_LOCATION_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateLocation = async (locationIdentifier, location) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		var updatedLocation = {};

		try {
			const res = api.patch(`/core/v2/locations/${locationIdentifier}`, location, config);
			const res2 = api.get(`/core/v2/locations/${locationIdentifier}/addresses`, config);
			const res3 = api.get(`/core/v2/locations/${locationIdentifier}/phones`, config);

			await axios.all([res, res2, res3])
				.then(axios.spread((...responses) => {
					updatedLocation = responses[0].data;
					updatedLocation.addresses = responses[1].data;
					updatedLocation.phones = responses[2].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: UPDATE_LOCATION_SUCCESS,
				payload: updatedLocation
			});
		} catch (err) {
			dispatch({
				type: UPDATE_LOCATION_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteLocation = async (location) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/appointments/v2/locations/${location.identifier}`, config);

			dispatch({
				type: DELETE_LOCATION,
				payload: location.identifier
			});
		} catch (err) {
			setAlert('Delete Location Error', 'Unknown Error', 'danger');
		}
	};

	const createLocationAddress = async (locationIdentifier, address) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/locations/${locationIdentifier}/addresses`, address, config);

			dispatch({
				type: CREATE_LOCATION_ADDRESS_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_LOCATION_ADDRESS_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const createLocationPhone = async (locationIdentifier, phone) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/locations/${locationIdentifier}/phones`, phone, config);

			dispatch({
				type: CREATE_LOCATION_PHONE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_LOCATION_PHONE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	const clearSearch = () => dispatch({ type: CLEAR_SEARCH_TERM });

	return <LocationsContext.Provider
		value={{
			locations: state.locations,
			locationsNextIdentifier: state.locationsNextIdentifier,
			locationsSyncIdentifier: state.locationsSyncIdentifier,
			location: state.location,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getLocations,
			getLocation,
			createLocation,
			updateLocation,
			deleteLocation,
			createLocationAddress,
			createLocationPhone,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</LocationsContext.Provider>
}

export default LocationsState;