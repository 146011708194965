import {
	AUTHENTICATION_CHECK_SUCCESS,
	AUTHENTICATION_CHECK_FAIL,
	AUTHENTICATION_USER_ACCESS_SUCCESS,
	AUTHENTICATION_USER_ACCESS_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	SET_LOADING
} from '../types';

const authReducer = (state, action) => {
	switch (action.type) {
		case AUTHENTICATION_CHECK_SUCCESS:
			return {
				...state,
				authenticationToken: action.payload.authenticationToken,
				workspaces: action.payload.workspaces,
				currentWorkspace: action.payload.currentWorkspace,
				userIdentifier: action.payload.userIdentifier,
				loading: false
			};
		case AUTHENTICATION_CHECK_FAIL:
			return {
				...state,
				authenticationToken: null,
				workspaces: null,
				loading: false
			};
		case AUTHENTICATION_USER_ACCESS_SUCCESS:
			return {
				...state,
				userAccess: action.payload,
				loading: false
			};
		case AUTHENTICATION_USER_ACCESS_FAIL:
			return {
				...state,
				userAccess: null,
				loading: false
			};
		case LOGIN_SUCCESS:
			localStorage.setItem('token', action.payload.authenticationToken);

			return {
				...state
			};
		case LOGIN_FAIL:
		case LOGOUT:
			return {
				...state,
				authenticationToken: null,
				error: action.payload,
				token: null,
				userIdentifier: null,
				isAuthenticated: false,
				loading: false,
				user: null
			};
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default authReducer