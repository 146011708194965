import {
	GET_WORKSPACES,
	CREATE_WORKSPACE_SUCCESS,
	CREATE_WORKSPACE_FAIL,
	UPDATE_WORKSPACE_SUCCESS,
	UPDATE_WORKSPACE_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const workspacesReducer = (state, action) => {
	switch (action.type) {
		case GET_WORKSPACES:
			return {
				...state,
				workspaces: action.payload,
				loading: false
			}
		case CREATE_WORKSPACE_SUCCESS:
			return {
				...state,
				workspace: action.payload,
				workspaces: [...state.workspaces, action.payload],
				success: 'Workspace successfully created',
				loading: false
			}
		case CREATE_WORKSPACE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_WORKSPACE_SUCCESS:
			return {
				...state,
				workspace: action.payload,
				workspaces: state.workspaces.map(workspace =>
					workspace.identifier === action.payload.identifier ? action.payload : workspace
				),
				success: 'Workspace successfully updated',
				loading: false
			}
		case UPDATE_WORKSPACE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default workspacesReducer