import {
	GET_LOCATIONS_SUCCESS,
	GET_MORE_LOCATIONS_SUCCESS,
	GET_LOCATION_SUCCESS,
	CREATE_LOCATION_SUCCESS,
	CREATE_LOCATION_FAIL,
	UPDATE_LOCATION_SUCCESS,
	UPDATE_LOCATION_FAIL,
	DELETE_LOCATION,
	CREATE_LOCATION_ADDRESS_SUCCESS,
	CREATE_LOCATION_ADDRESS_FAIL,
	CREATE_LOCATION_PHONE_SUCCESS,
	CREATE_LOCATION_PHONE_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
} from "../types";

const locationsReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_TERM:
			return {
				...state,
				search: action.payload,
			}
		case GET_LOCATIONS_SUCCESS:
			return {
				...state,
				locations: action.payload.items,
				locationsSyncIdentifier: action.payload.syncIdentifier,
				locationsNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_MORE_LOCATIONS_SUCCESS:
			return {
				...state,
				locations: [...state.locations.concat(action.payload.items)],
				locationsSyncIdentifier: action.payload.syncIdentifier,
				locationsNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_LOCATION_SUCCESS:
			return {
				...state,
				location: action.payload,
				loading: false
			}
		case CREATE_LOCATION_SUCCESS:
			return {
				...state,
				location: action.payload,
				locations: [...state.locations, action.payload],
				success: 'Location successfully created',
				loading: false
			}
		case CREATE_LOCATION_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_LOCATION_SUCCESS:
			return {
				...state,
				location: action.payload,
				locations: state.locations.map(location =>
					location.identifier === action.payload.identifier ? action.payload : location
				),
				success: 'Location successfully updated',
				loading: false
			}
		case UPDATE_LOCATION_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_LOCATION:
			return {
				...state,
				locations: state.locations.filter(
					location => location.identifier !== action.payload
				),
				loading: false
			}
		case CREATE_LOCATION_ADDRESS_SUCCESS:
			return {
				...state,
				success: 'Location successfully updated',
				loading: false
			}
		case CREATE_LOCATION_ADDRESS_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case CREATE_LOCATION_PHONE_SUCCESS:
			return {
				...state,
				success: 'Location successfully updated',
				loading: false
			}
		case CREATE_LOCATION_PHONE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case CLEAR_SEARCH_TERM:
			return {
				...state,
				search: ''
			}
		default:
			return state;
	}
}

export default locationsReducer