import {
	GET_ACTION,
	SET_LOADING
} from '../types';

const actionReducer = (state, action) => {
	switch (action.type) {
		case GET_ACTION:
			return {
				...state,
				action: action.payload
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default actionReducer