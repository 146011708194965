import {
	GET_CUSTOM_OBJECTS,
	GET_CUSTOM_OBJECT,
	GET_CUSTOM_OBJECT_DATA_LIST,
	GET_CUSTOM_OBJECT_DATA,
	CREATE_CUSTOM_OBJECT_SUCCESS,
	CREATE_CUSTOM_OBJECT_FAIL,
	UPDATE_CUSTOM_OBJECT_SUCCESS,
	UPDATE_CUSTOM_OBJECT_FAIL,
	DELETE_CUSTOM_OBJECT_SUCCESS,
	DELETE_CUSTOM_OBJECT_FAIL,
	GET_CUSTOM_ATTRIBUTES,
	CREATE_CUSTOM_SUCCESS,
	CREATE_CUSTOM_FAIL,
	CREATE_CUSTOM_ATTRIBUTE_SUCCESS,
	CREATE_CUSTOM_ATTRIBUTE_FAIL,
	UPDATE_RESOURCE_FIELDS_SUCCESS,
	UPDATE_RESOURCE_FIELDS_FAIL,
	DELETE_CUSTOM_SUCCESS,
	DELETE_CUSTOM_FAIL,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const customObjectsReducer = (state, action) => {
	switch (action.type) {
		case GET_CUSTOM_OBJECTS:
			return {
				...state,
				customObjects: action.payload,
				loading: false
			}
		case GET_CUSTOM_OBJECT:
			return {
				...state,
				customObject: action.payload,
				loading: false
			}
		case GET_CUSTOM_ATTRIBUTES:
				return {
					...state,
					customAttributes: action.payload,
					loading: false
				}
		case GET_CUSTOM_OBJECT_DATA_LIST:
			return {
				...state,
				customObjectDataList: action.payload,
				loading: false
			}
		case GET_CUSTOM_OBJECT_DATA:
			return {
				...state,
				customObjectData: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_OBJECT_SUCCESS:
			return {
				...state,
				customObject: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_OBJECT_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case UPDATE_CUSTOM_OBJECT_SUCCESS:
			return {
				...state,
				customObject: action.payload,
				loading: false
			}
		case UPDATE_CUSTOM_OBJECT_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case DELETE_CUSTOM_OBJECT_SUCCESS:
			return {
				...state,
				customObjects: state.customObjects.filter(
					customObject => customObject.identifier !== action.payload
				),
				customObject: null,
				loading: false
			}
		case DELETE_CUSTOM_OBJECT_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_SUCCESS:
			return {
				...state,
				success: 'Record successfully created.',
				customObject: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_ATTRIBUTE_SUCCESS:
			return {
				...state,
				success: 'Custom property successfully created.',
				customObject: action.payload,
				loading: false
			}
		case CREATE_CUSTOM_ATTRIBUTE_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		// Updates custom attributes
		case UPDATE_RESOURCE_FIELDS_SUCCESS:
			return {
				...state,
				success: 'Record successfully updated.',
				// customObject: action.payload,
				loading: false
			}
		case UPDATE_RESOURCE_FIELDS_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case DELETE_CUSTOM_SUCCESS:
			return {
				...state,
				customObjectDataList: state.customObjectDataList.filter(
					customObject => customObject.identifier !== action.payload
				),
				customObjectData: null,
				loading: false
			}
		case DELETE_CUSTOM_FAIL:
			return {
				...state,
				error: action.payload,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default customObjectsReducer