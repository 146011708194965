import {
	GET_ARTICLES,
	SET_LOADING
} from "../types";

const articlesReducer = (state, action) => {
	switch (action.type) {
		case GET_ARTICLES:
			return {
				...state,
				articles: action.payload,
				loading: false,
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default articlesReducer