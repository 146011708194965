const appointmentCreateReducer = (state, action) => {
	switch (action.type) {
		case 'appointment_modal_display':
			return {
				...state,
				display: action.payload
			}
		case 'set_customer':
			return {
				...state,
				appointment: {
					...state.appointment,
					customers: [
						...state.appointment.customers,
						action.payload
					]
				}
			}
		case 'reset_customers':
			return {
				...state,
				appointment: {
					...state.appointment,
					customers: []
				}
			}
		case 'set_instance':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: [
						...state.appointment.instances,
						action.payload
					]
				}
			}
		case 'reset_instances':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: []
				}
			}
		case 'set_service':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								services: [
									...instance.services,
									action.payload.service
								]
							}
							: instance
					)
				}
			}
		case 'reset_services':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								services: []
							}
							: instance
					)
				}
			}
		case 'set_resource':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								resources: [
									...instance.resources,
									action.payload.resource
								]
							}
							: instance
					)
				}
			}
		case 'reset_resources':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								resources: []
							}
							: instance
					)
				}
			}

		case 'set_starts_on':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								startsOn: action.payload.startsOn,
							}
							: instance
					)
				}
			}
		case 'reset_starts_on':
			return {
				...state,
				appointment: {
					...state.appointment,
					instances: state.appointment.instances.map((instance, index) =>
						index === action.payload.instanceKey
							? {
								...instance,
								startsOn: null
							}
							: instance
					)
				}
			}
		case 'loading':
			return {
				...state,
				loading: true
			}
		default:
			return state;
	}
}

export default appointmentCreateReducer