import React, { useReducer } from "react";
import api from "../../network/Api";
import ApplicationContext from "./applicationContext";
import ApplicationReducer from "./applicationReducer";
import {
	GET_ACTION,
	GET_ENABLED_TOGGLES,
	GET_APPLICATION_DETAILS,
	SET_LOADING
} from "../types";

const ApplicationState = props => {
	const initialState = {
		details: {},
		enabledToggles: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(ApplicationReducer, initialState);

	const getDetails = async () => {
		setLoading();

		let details = {
			"status": [],
			"portal": []
		};

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get('/core/v2/application/status', config);

			details.status = res.data

			dispatch({
				type: GET_APPLICATION_DETAILS,
				payload: details
			});
		} catch (err) {
			dispatch({
				type: GET_APPLICATION_DETAILS,
				payload: details
			});
		}
	};

	const getEnabledToggles = async () => {
		setLoading();

		api.get(`/core/v2/applications/toggles/enabled`, {
			validateStatus: function (status) {
				return status === 200;
			}
		}).then(function (response) {
			const action = response.data;

			dispatch({
				type: GET_ENABLED_TOGGLES,
				payload: action
			});
		}).catch(function (error) {
			dispatch({
				type: GET_ENABLED_TOGGLES,
				payload: []
			});
		});
	};

	const setTitle = (title) => {
		document.title = title + ' - BotScheduling';
	};

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <ApplicationContext.Provider
		value={{
			details: state.details,
			enabledToggles: state.enabledToggles,
			loading: state.loading,
			getDetails,
			getEnabledToggles,
			setTitle,
			setLoading
		}}
	>
		{props.children}
	</ApplicationContext.Provider>
}

export default ApplicationState;