import React, { useReducer } from 'react';
import api from "../../network/Api";
import AuthContext from './authContext';
import authReducer from './authReducer';
import setAuthToken from '../../utils/setAuthToken';
import setWorkspaceIdentifier from '../../utils/setWorkspaceIdentifier';
import {
	AUTHENTICATION_CHECK_SUCCESS,
	AUTHENTICATION_CHECK_FAIL,
	AUTHENTICATION_USER_ACCESS_SUCCESS,
	AUTHENTICATION_USER_ACCESS_FAIL,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	SET_LOADING
} from '../types';

import axios from "axios";

const AuthState = props => {
	const initialState = {
		authenticationToken: null,
		workspaces: null,
		currentWorkspace: null,
		userIdentifier: null,
		userAccess: null,

		isAuthenticated: null,
		loading: true,
		error: null
	};

	const [ state, dispatch ] = useReducer(authReducer, initialState);

	const loadAuthentication = async (token, workspaceIdentifier) => {
		setLoading();

		setAuthToken(token);
		setWorkspaceIdentifier(workspaceIdentifier);

		if (token === null) {
			dispatch({
				type: AUTHENTICATION_CHECK_FAIL,
				payload: null
			});

			return;
		}

		api.get(`/auth/v2/authentication`, {
			validateStatus: function (status) {
				return status === 200
			}
		}).then(function (response) {
			const { authenticationToken, workspaces, userIdentifier } = response.data;

			const filterWorkspaces = response.data.workspaces.filter(function (workspace) {
				return workspace.identifier === workspaceIdentifier;
			});

			dispatch({
				type: AUTHENTICATION_CHECK_SUCCESS,
				payload: {
					authenticationToken: authenticationToken,
					workspaces: workspaces,
					currentWorkspace: filterWorkspaces.length > 0 ? filterWorkspaces[0] : null,
					userIdentifier: userIdentifier
				}
			});
		}).catch(function (error) {
			dispatch({
				type: AUTHENTICATION_CHECK_FAIL,
				payload: null
			});
		});
	};

	// Login User
	const login = async formData => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post('/auth/v2/authentication', formData, config);

			dispatch({
				type: LOGIN_SUCCESS,
				payload: res.data
			});

			const workspaceIdentifier = localStorage.getItem('workspaceIdentifier');
			loadAuthentication(res.data.authenticationToken, workspaceIdentifier);
		} catch (err) {
			dispatch({
				type: LOGIN_FAIL,
				payload: err.response.data.message
			});
		}
	};

	// Logout
	const logout = () => {
		setLoading();

		const token = localStorage.getItem('token');
		setAuthToken(token);

		if (!token) {
			dispatch({
				type: LOGOUT,
				payload: ''
			});

			return;
		}

		try {
			api.delete(`/auth/v2/authentication`, {
				headers: {
					'Content-Type': 'application/json'
				},
				validateStatus: function (status) {
					return status === 204;
				}
			});

			localStorage.removeItem('token');
			setAuthToken(null);
		} catch (err) {
			//nothing to see here
		}

		dispatch({
			type: LOGOUT,
			payload: ''
		});
	};

	const getUserAccess = async () => {
		setLoading();

		api.get(`/core/v2/users/current/access`, {
			validateStatus: function (status) {
				return status === 200
			}
		}).then(function (response) {
			dispatch({
				type: AUTHENTICATION_USER_ACCESS_SUCCESS,
				payload: response.data
			});
		}).catch(function (error) {
			dispatch({
				type: AUTHENTICATION_USER_ACCESS_FAIL
			});
		});
	};

	const setLoading = () => dispatch({ type: SET_LOADING });

	return (
		<AuthContext.Provider
			value={{
				authenticationToken: state.authenticationToken,
				token: state.token,
				workspaces: state.workspaces,
				currentWorkspace: state.currentWorkspace,
				userAccess: state.userAccess,
				userIdentifier: state.userIdentifier,
				isAuthenticated: state.isAuthenticated,
				loading: state.loading,
				error: state.error,
				loadAuthentication,
				login,
				logout,
				getUserAccess,
				setLoading
			}}>

			{ props.children }
		</AuthContext.Provider>
	);
};

export default AuthState;