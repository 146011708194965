import React, { useEffect, useState, useRef, useContext } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap'
import { Button } from "../components/layout/BotSchedulingUI";
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useForm } from "react-hook-form";
import Loading from "../components/Loading";
import '../vendor/styles/pages/chat.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { format } from 'date-fns';

import ChatContext from "../context/chat/chatContext";

const Chat = ({handleClearConversations, ...props}) => {
	const chatContext = useContext(ChatContext);

	const [message, setMessage] = useState('');

	const handleInputChange = (e) => {
		setMessage(e.target.value);
	};

	console.log(chatContext);

	const scrollRef = useRef(null);


	const handleChat = () => {
		chatContext.sendChat(message.trim());
	}

	const handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			handleChat();
			setMessage('');
		}
	};

	useEffect(() => {
		chatContext.getChats();
	}, []);

	useEffect(() => {
		if (scrollRef.current) {
			scrollRef.current._container.scrollTop = scrollRef.current._container.scrollHeight;
		}
	}, [chatContext.chats]);

	return (
		<div>
			<div className={`chat-wrapper chat-sidebox-open`}>
				<div className="flex-grow-1 position-relative overflow-hidden">
					<Row noGutters className="h-100">
						<Col className="d-flex flex-column">
							<div className="flex-grow-1 position-relative">
								<PerfectScrollbar options={{suppressScrollX: true, wheelPropagation: true}}
								                  className="chat-messages chat-scroll"
								                  ref={scrollRef}
								>

									{chatContext.chats.map(chat =>
										<div key={chat.createdOn} className={`mb-4 chat-message-${chat.role === 'user' ? 'right' : 'left'}`}>
											<div className={`text-center`}>
												<img src={chat.role === 'user' ? `https://www.gravatar.com/avatar/e1f6370978af486842ef36fea3f19554?d=https://app.botscheduling.com/img/avatars/1.png` : `https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQA1jpTVrugJwXmZc6WPdw6VPSZ2Tm5mIq7A&s`}
												     className="ui-w-40 rounded-circle" alt="User"/>
												<div className="text-muted small text-nowrap mt-2">
													{format(new Date(chat.createdOn), "h:mm aa")}
												</div>
											</div>
											<div className={`flex-shrink-1 bg-light rounded py-2 px-3 ${chat.role === 'user' ? 'mr-3' : 'ml-3'}`}>
												<div
													className="font-weight-semibold mb-1">{chat.role === 'user' ? 'You' : 'Bot'}</div>
												{chat.content}
											</div>
										</div>
									)}

									{((chatContext.chats.length === 0 && chatContext.loading) || chatContext.chatLoading) &&
										<div className="mb-2">
											<Loading ready={false} />
										</div>
									}

									{(chatContext.chats.length === 0 && !chatContext.loading && !chatContext.chatLoading) &&
										<div className="mb-2 text-center">
											<FontAwesomeIcon icon="robot" className="text-light" size="2xl" />
											<p className="text-light">Start chatting to ask me anything.</p>
										</div>
									}
								</PerfectScrollbar>
							</div>

							<hr className="border-light m-0"/>

							<div className="flex-grow-0 pt-4">
								<InputGroup>
									<Form.Control
										placeholder="Type your message"
										size="lg"
										value={message}
										onChange={handleInputChange}
										onKeyPress={handleKeyPress}
									/>
									<InputGroup.Append>
										<Button variant="primary" onClick={handleChat} disabled={message.length === 0}>
											<FontAwesomeIcon icon="paper-plane" className="mr-2" size="lg"/>
										</Button>
									</InputGroup.Append>
								</InputGroup>

								<div className="m-0 p-0 mt-4 text-center">
									<Button variant="primary" className="btn btn-sm" onClick={handleClearConversations} loading={chatContext.resetLoading}>CLEAR CONVERSATION</Button>
									<div className="text-small text-muted small mt-2">Chat can be inaccurate or mistaken.</div>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
	);
}

export default Chat