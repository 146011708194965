import {
	LOAD_STATUSES_SUCCESS,
	LOAD_STATUSES_FAIL,
	GET_STATUS_SUCCESS,
	CREATE_STATUS_SUCCESS,
	CREATE_STATUS_FAIL,
	UPDATE_STATUS_SUCCESS,
	UPDATE_STATUS_FAIL,
	DELETE_STATUS,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const statusesReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_TERM:
			return {
				...state,
				search: action.payload,
			}
		case LOAD_STATUSES_SUCCESS:
			return {
				...state,
				statuses: action.payload,
				error: null,
				loading: false,
			}
		case LOAD_STATUSES_FAIL:
			return {
				...state,
				statuses: [],
				error: action.payload,
				loading: false,
			}
		case GET_STATUS_SUCCESS:
			return {
				...state,
				status: action.payload,
				loading: false
			}
		case CREATE_STATUS_SUCCESS:
			return {
				...state,
				status: action.payload,
				statuses: [...state.statuses, action.payload],
				success: 'Status successfully created',
				loading: false
			}
		case CREATE_STATUS_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_STATUS_SUCCESS:
			return {
				...state,
				status: action.payload,
				statuses: state.statuses.map(status =>
					status.identifier === action.payload.identifier ? action.payload : status
				),
				success: 'Status successfully updated',
				loading: false
			}
		case UPDATE_STATUS_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_STATUS:
			return {
				...state,
				statuses: state.statuses.filter(
					status => status.identifier !== action.payload
				),
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case CLEAR_SEARCH_TERM:
			return {
				...state,
				search: ''
			}
		default:
			return state;
	}
}

export default statusesReducer