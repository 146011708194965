import React from 'react';
import { Form } from 'react-bootstrap';

const InputControl = ({form, formControl}) => {
	const {
		register,
		formState: { errors },
	} = form;

	return (
		<Form.Control
			{...register(`${formControl.name}`, formControl.validationRules)}
			name={formControl.name}
			type="input"
			size={formControl.size}
			defaultValue={formControl.value}
			placeholder={formControl.placeholder ?? ""}
			isInvalid={
				errors[`${formControl.name}`] !== undefined
			}
		/>
	);
}

export default InputControl