import React, { useReducer } from "react";
import api from "../../network/Api";
import WorkspacesContext from "./workspacesContext";
import WorkspacesReducer from "./workspacesReducer";
import {
	GET_WORKSPACES,
	CREATE_WORKSPACE_SUCCESS,
	CREATE_WORKSPACE_FAIL,
	UPDATE_WORKSPACE_SUCCESS,
	UPDATE_WORKSPACE_FAIL,
	SET_LOADING,
	RESET_SUCCESS,
	RESET_ERROR
} from "../types";

const WorkspacesState = props => {
	const initialState = {
		workspaces: [],
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(WorkspacesReducer, initialState);

	const getWorkspaces = async () => {
		setLoading();

 		const res = await api.get(`/core/v2/workspaces`, {transformRequest: (data, headers) => {
				delete headers.common['Workspace-Identifier'];
				return data;
			}
		});

		dispatch({
			type: GET_WORKSPACES,
			payload: res.data
		});
	};

	const createWorkspace = async (data) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/core/v2/workspaces`, data, config);

			dispatch({
				type: CREATE_WORKSPACE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_WORKSPACE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateWorkspace = async (workspaceIdentifier, workspace) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/core/v2/workspaces/${workspaceIdentifier}`, workspace, config);

			dispatch({
				type: UPDATE_WORKSPACE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_WORKSPACE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <WorkspacesContext.Provider
		value={{
			workspaces: state.workspaces,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getWorkspaces,
			createWorkspace,
			updateWorkspace,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</WorkspacesContext.Provider>
}

export default WorkspacesState;