import {
	LOAD_SERVICES_SUCCESS,
	LOAD_MORE_SERVICES_SUCCESS,
	GET_SERVICE_SUCCESS,
	CREATE_SERVICE_SUCCESS,
	CREATE_SERVICE_FAIL,
	UPDATE_SERVICE_SUCCESS,
	UPDATE_SERVICE_FAIL,
	DELETE_SERVICE,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING, LOAD_MORE_CUSTOMERS_SUCCESS
} from "../types";

const servicesReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_TERM:
			return {
				...state,
				search: action.payload,
			}
		case LOAD_SERVICES_SUCCESS:
			return {
				...state,
				services: action.payload.items,
				servicesSyncIdentifier: action.payload.syncIdentifier,
				servicesNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case LOAD_MORE_SERVICES_SUCCESS:
			return {
				...state,
				services: [...state.services.concat(action.payload.items)],
				servicesSyncIdentifier: action.payload.syncIdentifier,
				servicesNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_SERVICE_SUCCESS:
			return {
				...state,
				service: action.payload,
				loading: false
			}
		case CREATE_SERVICE_SUCCESS:
			return {
				...state,
				service: action.payload,
				services: [...state.services, action.payload],
				success: 'Service successfully created',
				loading: false
			}
		case CREATE_SERVICE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_SERVICE_SUCCESS:
			return {
				...state,
				service: action.payload,
				services: state.services.map(service =>
					service.identifier === action.payload.identifier ? action.payload : service
				),
				success: 'Service successfully updated',
				loading: false
			}
		case UPDATE_SERVICE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_SERVICE:
			return {
				...state,
				services: state.services.filter(
					service => service.identifier !== action.payload
				),
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case CLEAR_SEARCH_TERM:
			return {
				...state,
				search: ''
			}
		default:
			return state;
	}
}

export default servicesReducer