import React, { useContext, useReducer } from "react";
import api from "../../network/Api";
import ServicesContext from "./servicesContext";
import ServicesReducer from "./servicesReducer";
import {
	LOAD_SERVICES_SUCCESS,
	LOAD_MORE_SERVICES_SUCCESS,
	GET_SERVICE_SUCCESS,
	CREATE_SERVICE_SUCCESS,
	CREATE_SERVICE_FAIL,
	UPDATE_SERVICE_SUCCESS,
	UPDATE_SERVICE_FAIL,
	DELETE_SERVICE,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

import AlertContext from "../alert/alertContext";

const ServicesState = props => {
	const initialState = {
		services: [],
		servicesNextIdentifier: '',
		servicesSyncIdentifier: '',
		service: {},
		search: '',
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(ServicesReducer, initialState);

	const alertContext = useContext(AlertContext);
	const { setAlert } = alertContext;

	//Load services
	const loadServices = async (itemsPerPage, maxNumberOfPages, search = '', searchBy = '') => {
		setLoading();

		let searchUrl = '';
		if (search === '') {
			dispatch({
				type: CLEAR_SEARCH_TERM
			});
		} else {
			searchUrl = `&search=${search}`;
			if (searchBy !== '') {
				searchUrl = `&search=${search}&searchBy=${searchBy}`;
			}

			dispatch({
				type: SEARCH_TERM,
				payload: search
			});
		}

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		let nextIdentifier = '';
		for (let i = 1; i <= maxNumberOfPages; i++) {
			if (i === 1) {
				const res = await api.get(`/appointments/v2/services?limit=${itemsPerPage}${searchUrl}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_SERVICES_SUCCESS,
					payload: res.data
				});
			} else if (nextIdentifier !== '') {
				const res = await api.get(`/appointments/v2/services?nextIdentifier=${nextIdentifier}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_MORE_SERVICES_SUCCESS,
					payload: res.data
				});
			} else {
				break;
			}
		}
	}

	const getService = async (serviceIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.get(`/appointments/v2/services/${serviceIdentifier}`, config);

			dispatch({
				type: GET_SERVICE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: GET_SERVICE_SUCCESS,
				payload: null
			});
		}
	};

	const createService = async (service) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/appointments/v2/services`, service, config);

			dispatch({
				type: CREATE_SERVICE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_SERVICE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateService = async (serviceIdentifier, service) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		try {
			const res = await api.patch(`/appointments/v2/services/${serviceIdentifier}`, service, config);

			dispatch({
				type: UPDATE_SERVICE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: UPDATE_SERVICE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteService = async (service) => {
		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/appointments/v2/services/${service.identifier}`, config);

			dispatch({
				type: DELETE_SERVICE,
				payload: service.identifier
			});
		} catch (err) {
			setAlert('Delete Service Error', 'Unknown Error', 'danger');
		}
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	const clearSearch = () => dispatch({ type: CLEAR_SEARCH_TERM });

	return <ServicesContext.Provider
		value={{
			services: state.services,
			servicesNextIdentifier: state.servicesNextIdentifier,
			servicesSyncIdentifier: state.servicesSyncIdentifier,
			service: state.service,
			success: state.success,
			error: state.error,
			loading: state.loading,
			loadServices,
			getService,
			createService,
			updateService,
			deleteService,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</ServicesContext.Provider>
}

export default ServicesState;