import React from "react";
import { Link } from "react-router-dom";
import styles from "./NotFound.module.scss";

function NotFound() {
  return (
    <div className={styles.Container}>
      <div className="text-center">
        <h1 className={styles.Status}>404</h1>
        <h2 className={styles.Message}>Not Found</h2>
        <Link className={styles.Link} to={"/"}>&larr; Back to the Dashboard</Link>
      </div>
    </div>
  )
}

export default NotFound
