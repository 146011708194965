import React from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import AdapterDayjs from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiOutlinedInput-root': {
			paddingTop: '6px',
			paddingBottom: '6px',
		},
		'& .MuiOutlinedInput-input': {
			color: '#4E5155',
			padding: '0.75rem 1.25rem',
			fontSize: '1rem',
			lineHeight: '1.5',
			borderRadius: '0.25rem',
		},
	},
}));

const DateControl = ({ form, formControl }) => {
	const classes = useStyles();

	const {
		control,
		formState: { errors },
	} = form;

	return (
		<Controller
			name={formControl.name}
			control={control}
			rules={formControl.validationRules}
			defaultValue={formControl.value ? dayjs(formControl.value) : null}
			render={({ field: { onChange, value }, fieldState: { invalid } }) => (
				<>
					<DatePicker
						className={classes.root}
						value={value}
						onChange={(newValue) => onChange(newValue ? newValue.toDate() : null)}
						renderInput={(params) => (
							<TextField
								{...params}
								size={formControl.size}
								placeholder={formControl.placeholder ?? ''}
								error={!!errors[formControl.name]}
								helperText={errors[formControl.name]?.message}
							/>
						)}
					/>
					{invalid && (
						<FormHelperText error>
							{errors[formControl.name]?.message}
						</FormHelperText>
					)}
				</>
			)}
		/>
	);
};

export default DateControl;
