import React, { useEffect, useState } from 'react';
import {
    Autocomplete as AutocompleteMUI,
    FormHelperText,
    MenuItem,
    TextField as TextFieldMUI
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    'root': {
        '& .MuiOutlinedInput-root': {
            paddingTop: '6px',
            paddingBottom: '6px'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.75rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem'
        },
    }
}));

const SearchableListControl = ({ form, formControl }) => {
    const classes = useStyles();

    const {
        formState: { errors },
        control
    } = form;

    const [defaultOption, setDefaultOption] = useState(null);

    useEffect(() => {
        // Find the default option from the formControl value
        if (formControl.value) {
            const option = formControl.options.find(opt => opt.value === formControl.value);
            setDefaultOption(option);
        }
    }, [formControl.value, formControl.options]);

    return (
        <Controller
            name={formControl.name}
            control={control}
            rules={formControl.validationRules ?? {}}
            defaultValue={formControl.value ?? null} // Ensure defaultValue is null if no initial value
            render={({ field: { onChange, value }, fieldState: { invalid } }) => {
                // Use defaultOption if value is not set yet
                const selectedOption = value ? formControl.options.find(option => option.value === value) : defaultOption;

                return (
                    <>
                        <AutocompleteMUI
                            options={formControl.options}
                            getOptionLabel={(option) => option.displayName}
                            value={selectedOption}
                            onChange={(event, newValue) => onChange(newValue?.value || null)}
                            className={classes['root']}
                            renderInput={(params) => (
                                <TextFieldMUI
                                    {...params}
                                    variant="outlined"
                                    error={invalid}
                                />
                            )}
                            renderOption={(props, option) => (
                                <li {...props}>
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.icon && <FontAwesomeIcon icon={option.icon} className="pr-2" />}{option.displayName}
                                    </MenuItem>
                                </li>
                            )}
                        />
                        {invalid && (
                            <FormHelperText error>{errors[formControl.name]?.message}</FormHelperText>
                        )}
                    </>
                );
            }}
        />
    );
}

export default SearchableListControl;
