import React, { useReducer } from "react";
import api from "../../network/Api";
import SchedulesContext from "./schedulesContext";
import SchedulesReducer from "./schedulesReducer";
import {
	LOAD_SCHEDULES_BY_RESOURCE_SUCCESS,
	LOAD_SCHEDULES_BY_RESOURCE_FAIL,

	LOAD_SCHEDULES_SUCCESS,
	LOAD_MORE_SCHEDULES_SUCCESS,
	GET_SCHEDULE_SUCCESS,
	CREATE_SCHEDULE_SUCCESS,
	CREATE_SCHEDULE_FAIL,
	UPDATE_SCHEDULE_SUCCESS,
	UPDATE_SCHEDULE_FAIL,
	DELETE_SCHEDULE,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

import axios from "axios";

const SchedulesState = props => {
	const initialState = {
		schedules: [],
		schedulesNextIdentifier: '',
		schedulesSyncIdentifier: '',
		schedule: {},
		search: '',
		success: null,
		error: null,
		loading: true
	}

	const [ state, dispatch ] = useReducer(SchedulesReducer, initialState);

	const loadSchedulesByResource = async (resourceIdentifier) => {
		setLoading();

		api.get(`/appointments/v1/schedule-container/${resourceIdentifier}`, {
			validateStatus: function (status) {
				return status === 200;
			}
		})
		.then(function (response) {
			let payload = {};
			payload[resourceIdentifier] = response.data.schedules;
			dispatch({
				type: LOAD_SCHEDULES_BY_RESOURCE_SUCCESS,
				payload: payload
			});
		})
		.catch(function (error) {
			dispatch({
				type: LOAD_SCHEDULES_BY_RESOURCE_FAIL,
				payload: null
			});
		});
	}

	//Load schedules
	const loadSchedules = async (itemsPerPage, maxNumberOfPages, search = '', searchBy = '') => {
		setLoading();

		let searchUrl = '';
		if (search === '') {
			dispatch({
				type: CLEAR_SEARCH_TERM
			});
		} else {
			searchUrl = `&search=${search}`;
			if (searchBy !== '') {
				searchUrl = `&search=${search}&searchBy=${searchBy}`;
			}

			dispatch({
				type: SEARCH_TERM,
				payload: search
			});
		}

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		let nextIdentifier = '';
		for (let i = 1; i <= maxNumberOfPages; i++) {
			if (i === 1) {
				const res = await api.get(`/appointments/v2/schedules?limit=${itemsPerPage}${searchUrl}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_SCHEDULES_SUCCESS,
					payload: res.data
				});
			} else if (nextIdentifier !== '') {
				const res = await api.get(`/appointments/v2/schedules?nextIdentifier=${nextIdentifier}`, config);
				nextIdentifier = res.data.nextIdentifier;

				dispatch({
					type: LOAD_MORE_SCHEDULES_SUCCESS,
					payload: res.data
				});
			} else {
				break;
			}
		}
	}

	const getSchedule = async (scheduleIdentifier) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		var schedule = {};

		try {
			const res = api.get(`/appointments/v2/schedules/${scheduleIdentifier}`, config);

			await axios.all([res])
				.then(axios.spread((...responses) => {
					schedule = responses[0].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: GET_SCHEDULE_SUCCESS,
				payload: schedule
			});
		} catch (err) {
			dispatch({
				type: GET_SCHEDULE_SUCCESS,
				payload: null
			});
		}
	};

	const createSchedule = async (resourceIdentifier, schedule) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 201;
			}
		}

		try {
			const res = await api.post(`/appointments/v1/schedules/${resourceIdentifier}`, schedule, config);

			dispatch({
				type: CREATE_SCHEDULE_SUCCESS,
				payload: res.data
			});
		} catch (err) {
			dispatch({
				type: CREATE_SCHEDULE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const updateSchedule = async (scheduleIdentifier, schedule) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 200;
			}
		}

		console.log(schedule);

		var updatedSchedule = {};

		try {
			const res = api.patch(`/appointments/v2/schedules/${scheduleIdentifier}`, schedule, config);

			await axios.all([res])
				.then(axios.spread((...responses) => {
					updatedSchedule = responses[0].data;
				}))
				.catch(errors => {
					//todo do something here?
				});

			dispatch({
				type: UPDATE_SCHEDULE_SUCCESS,
				payload: updatedSchedule
			});
		} catch (err) {
			dispatch({
				type: UPDATE_SCHEDULE_FAIL,
				payload: err.response.data.message
			});
		}
	};

	const deleteSchedule = async (schedule) => {
		setLoading();

		const config = {
			headers: {
				'Content-Type': 'application/json'
			},
			validateStatus: function (status) {
				return status === 204;
			}
		}

		try {
			await api.delete(`/appointments/v2/schedules/${schedule.identifier}`, config);

			dispatch({
				type: DELETE_SCHEDULE,
				payload: schedule.identifier
			});
		} catch (err) {
			//setAlert('Delete Schedule Error', 'Unknown Error', 'danger');
		}
	};
	
	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	const clearSearch = () => dispatch({ type: CLEAR_SEARCH_TERM });

	return <SchedulesContext.Provider
		value={{
			schedules: state.schedules,
			schedulesNextIdentifier: state.schedulesNextIdentifier,
			schedulesSyncIdentifier: state.schedulesSyncIdentifier,
			schedule: state.schedule,
			success: state.success,
			error: state.error,
			loading: state.loading,
			loadSchedulesByResource,
			loadSchedules,
			getSchedule,
			createSchedule,
			updateSchedule,
			deleteSchedule,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</SchedulesContext.Provider>
}

export default SchedulesState;