import React, { Fragment } from 'react';
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText, ListItemText, TextField as TextFieldMUI, Autocomplete as AutocompleteMUI } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    'root': {
        '& .MuiOutlinedInput-root': {
            paddingTop: '6px',
            paddingBottom: '6px'
        },
        '& .MuiOutlinedInput-input': {
            color: '#4E5155',
            padding: '0.75rem 1.25rem',
            fontSize: '1rem',
            lineHeight: '1.5',
            borderRadius: '0.25rem'
        },
    }
}));

const timezones = require('../../../../data/TimeZoneList.json');

const TimeZoneList = ({ name, label, rules }) => {
    const classes = useStyles();

    const { formState: { errors }, control } = useFormContext();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules ?? {}}
            render={({ field: { onChange, value }, fieldState: { invalid } }) => (
                <>
                    <AutocompleteMUI
                        options={timezones}
                        getOptionLabel={(option) => option.displayName}
                        onChange={(event, newValue) => onChange(newValue?.value || null)}
                        className={classes['root']}
                        renderInput={(params) => (
                            <TextFieldMUI
                                {...params}
                                label={label ?? ''}
                                variant="outlined"
                                InputLabelProps={{
                                    // ... (InputLabelProps styling)
                                }}
                            />
                        )}
                        renderOption={(props, option) => (
                            <li {...props}>
                                <ListItemText primary={option.displayName} />
                            </li>
                        )}
                    />
                    {invalid && (
                        <FormHelperText error>{errors[name]?.message}</FormHelperText>
                    )}
                </>
            )}
        />
    );
};

export default TimeZoneList;