import React from "react";
import { Modal } from "react-bootstrap";
import SimpleModalFooter from "./SimpleModalFooter";
import SimpleModalHeader from "./SimpleModalHeader";

const SimpleModal = ({header, footer, padding, children, ...props}) => {
	return (
		<Modal {...props}>
			{header &&
				<SimpleModalHeader>{header}</SimpleModalHeader>
			}
			<Modal.Body className={padding ?? 'p-4'}>
				{children}
			</Modal.Body>
			{footer &&
				<SimpleModalFooter className="d-flex justify-content-between">{footer}</SimpleModalFooter>
			}
		</Modal>
	);
}

export default SimpleModal;