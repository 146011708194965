import React, { Fragment, useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Badge } from "react-bootstrap";
import {
  Avatar,
  Divider,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Skeleton
} from '@mui/material';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from 'prop-types'
import Sidenav from '../../vendor/libs/sidenav'
import layoutHelpers from './helpers'

import ToggleCheck from "../../components/ToggleCheck";
import Access from "../../components/Access";
import CreateResourceModal from "../../pages/manage/modals/CreateResourceModal";

import AuthContext from "../../context/auth/authContext";
import ResourcesContext from "../../context/resources/resourcesContext";

const { CUSTOMER_RESOURCE_CODE } = require('../../constants');

const LayoutSidenav = (props) => {
  const authContext = useContext(AuthContext);
  const resourcesContext = useContext(ResourcesContext);

  const history = useHistory();

  const loading = false;

  const { workspaces, currentWorkspace, loadAuthentication } = authContext;
  const { resources, getResources } = resourcesContext;

  const [ workspaceMenuOpen, setWorkspaceMenuOpen ] = useState(false);

  const toggleWorkspaceMenu = () => {
    setWorkspaceMenuOpen(!workspaceMenuOpen);
  }

  const isMenuActive = (url) => {
    return props.location.pathname.indexOf(url) === 0
  }

  const isMenuOpen = (url) => {
    return props.location.pathname.indexOf(url) === 0 && props.orientation !== 'horizontal'
  }

  const layoutSidenavClasses = () => {
    let bg = "sidenav-theme";

    if (props.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
      bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
    }

    return `bg-${bg} ` + (
        props.orientation !== 'horizontal'
            ? 'layout-sidenav'
            : 'layout-sidenav-horizontal container-p-x flex-grow-0'
    )
  }

  const toggleSidenav = (event) => {
    event.preventDefault()
    layoutHelpers.toggleCollapsed()
  }

  const switchWorkspace = (workspace) => {
    handleClose();
    const token = localStorage.getItem('token')
    localStorage.setItem('workspaceIdentifier', workspace.identifier)
    loadAuthentication(token, workspace.identifier);
    history.push('/dashboard');
  }

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [displayCreateResourceModal, setDisplayCreateResourceModal] = useState(false);

  const handleCloseModal = () => {
    setDisplayCreateResourceModal(false);
  }

  return (
    <Sidenav orientation={props.orientation} className={layoutSidenavClasses()}>
      {props.orientation !== 'horizontal' && (
        <Fragment>
          <div className="app-brand demo">
            <Avatar className="app-brand-logo demo bg-primary" style={{
              width: 40,
              height: 40,
              cursor: 'pointer'
            }}
            onClick={handleClick}>
              {currentWorkspace ? currentWorkspace.name.slice(0, 1) : <FontAwesomeIcon icon="circle-notch" size="sm" spin />}
            </Avatar>
            <div className="app-brand-text demo sidenav-text font-weight-normal ml-2">{currentWorkspace ? currentWorkspace.name : <Skeleton variant="rounded" width={100} sx={{bgcolor: 'white'}} />}</div>
            <a href="#toggle" className="layout-sidenav-toggle sidenav-link text-large ml-auto" onClick={toggleSidenav}>
              <FontAwesomeIcon icon="bars" size="sm" className="d-none d-lg-block" />
              <FontAwesomeIcon icon="chevron-left" size="sm" className="d-block d-lg-none" />
            </a>
          </div>

          <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
          >
            {workspaces.map(workspace => (
                workspace.status !== 'canceled' && (
                  <MenuItem key={workspace.identifier} selected={currentWorkspace.identifier === workspace.identifier} disabled={workspace.status !== 'active'} onClick={() => switchWorkspace(workspace)}>
                    <ListItemAvatar>
                        <Avatar variant="circular" className="bg-primary">{workspace.name.slice(0, 1)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={workspace.name} secondary={workspace.status} />
                  </MenuItem>
                )
            ))}
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <FontAwesomeIcon icon="add" />
              </ListItemIcon>
              <ListItemText>
                <Link to="/workspaces/create">CREATE NEW</Link>
              </ListItemText>
            </MenuItem>
          </Menu>
          <Sidenav.Divider className="m-0" />
        </Fragment>
      )}

      {/* Links */}
      <div className={`sidenav-inner ${props.orientation !== 'horizontal' ? 'py-0' : ''}`}>
        {/*<Sidenav.Header className="small font-weight-semibold">MENU</Sidenav.Header>*/}

        <Fragment>
          <ToggleCheck name="BotChatVersion">
            <Sidenav.RouterLink to="/bot" icon="robot" style={{backgroundColor: "rgba(255, 255, 255, 0.1)"}} loading={loading}>Book AI&nbsp;&nbsp;<Badge variant="outline-warning">NEW</Badge></Sidenav.RouterLink>
          </ToggleCheck>
          <Sidenav.RouterLink to="/dashboard" icon="gauge" loading={resources === null}>Dashboard</Sidenav.RouterLink>
          <Sidenav.RouterLink to="/calendar" icon="calendar" loading={resources === null}>Calendar</Sidenav.RouterLink>
          {resources?.filter(resource => (resource.resourceCode === CUSTOMER_RESOURCE_CODE)).map(resource => (
              <Sidenav.RouterLink to={`/customers`} icon={resource.icon} loading={resourcesContext.resources === null} key={resource.identifier}>
                {resource.pluralName}
              </Sidenav.RouterLink>
          ))}

          {resources?.filter(resource => (resource.type === 'custom')).map(resource => (
              <Sidenav.RouterLink to={`/resources/${resource.resourceCode}`} icon={resource.icon} loading={resourcesContext.resources === null} key={resource.identifier}>
                {resource.pluralName}
              </Sidenav.RouterLink>
          ))}

          <Access permission="resources.management.manage">
            <Sidenav.RouterLink className="mt-3" icon="add" to="#" onClick={() => setDisplayCreateResourceModal(true)}>
              ADD NEW
            </Sidenav.RouterLink>
            <CreateResourceModal displayModal={displayCreateResourceModal} handleCloseModal={handleCloseModal} {...props} />
          </Access>
        </Fragment>
      </div>
    </Sidenav>
  )
}

LayoutSidenav.propTypes = {
  orientation: PropTypes.oneOf(['vertical', 'horizontal'])
}

LayoutSidenav.defaultProps = {
  orientation: 'vertical'
}

export default LayoutSidenav;