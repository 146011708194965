//auth
export const AUTHENTICATION_CHECK_SUCCESS = 'AUTHENTICATION_CHECK_SUCCESS';
export const AUTHENTICATION_CHECK_FAIL = 'AUTHENTICATION_CHECK_FAIL';
export const AUTHENTICATION_USER_ACCESS_SUCCESS = 'AUTHENTICATION_USER_ACCESS_SUCCESS';
export const AUTHENTICATION_USER_ACCESS_FAIL = 'AUTHENTICATION_USER_ACCESS_FAIL';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

//layout
export const GET_LAYOUT = 'GET_LAYOUT';

export const INIT_MODAL = 'INIT_MODAL';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const RESET_MODAL = 'RESET_MODAL';
export const SET_FORM = 'SET_FORM';
export const SET_FORM_CONTROLS = 'SET_FORM_CONTROLS';
export const SET_FORM_DATA = 'SET_FORM_DATA';
export const RESET_FORM = 'RESET_FORM';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_SUBMIT_BUTTON = 'SET_SUBMIT_BUTTON';
export const SET_CANCEL_BUTTON = 'SET_CANCEL_BUTTON';
export const SET_TERTIARY_BUTTON = 'SET_TERTIARY_BUTTON';
export const SET_SUBMIT_HANDLER = 'SET_SUBMIT_HANDLER';
export const SET_CANCEL_HANDLER = 'SET_CANCEL_HANDLER';
export const SET_TERTIARY_HANDLER = 'SET_TERTIARY_HANDLER';

export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESET_ERROR = 'RESET_ERROR';

//attributes
export const LOAD_ATTRIBUTES_SUCCESS = 'LOAD_ATTRIBUTES_SUCCESS';
export const LOAD_ATTRIBUTES_FAIL = 'LOAD_ATTRIBUTES_FAIL';

//chat
export const GET_CHATS_SUCCESS = 'GET_CHATS_SUCCESS';
export const GET_CHATS_FAIL = 'GET_CHATS_FAIL';
export const APPEND_CHAT = 'APPEND_CHAT';
export const SEND_CHAT_SUCCESS = 'SEND_CHAT_SUCCESS';
export const SEND_CHAT_FAIL = 'SEND_CHAT_FAIL';
export const RESET_CHATS_SUCCESS = 'RESET_CHATS_SUCCESS';
export const RESET_CHATS_FAIL = 'RESET_CHATS_FAIL';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
export const SET_RESET_LOADING = 'SET_RESET_LOADING';

//resources
export const GET_RESOURCES = 'GET_RESOURCES';

//toggles
export const GET_ENABLED_TOGGLES = 'GET_ENABLED_TOGGLES';

//search
export const SEARCH_TERM = 'SEARCH_TERM';
export const CLEAR_SEARCH_TERM = 'CLEAR_SEARCH_TERM';

//application
export const GET_APPLICATION_DETAILS = 'GET_APPLICATION_DETAILS';

//dashboard
export const GET_DASHBOARD = 'GET_DASHBOARD';

//users
export const GET_USERS = 'GET_USERS';
export const GET_USER_AUTHENTICATION = 'GET_USER_AUTHENTICATION';
export const GET_LOGGED_IN_USER = 'GET_LOGGED_IN_USER';
export const GET_LOGGED_IN_USER_FAIL = 'GET_LOGGED_IN_USER_FAIL';
export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const REMOVE_USER = 'REMOVE_USER';
export const REMOVE_PENDING_USER = 'REMOVE_PENDING_USER';
export const INVITE_USER = 'INVITE_USER';
export const UPDATE_USER_ROLES = 'UPDATE_USER_ROLES';

//workspaces
export const GET_WORKSPACES = 'GET_WORKSPACES';
export const SET_WORKSPACES = 'SET_WORKSPACES';
export const CREATE_WORKSPACE_SUCCESS = 'CREATE_WORKSPACE_SUCCESS';
export const CREATE_WORKSPACE_FAIL = 'CREATE_WORKSPACE_FAIL';
export const UPDATE_WORKSPACE_SUCCESS = 'UPDATE_WORKSPACE_SUCCESS';
export const UPDATE_WORKSPACE_FAIL = 'UPDATE_WORKSPACE_FAIL';
export const DELETE_WORKSPACE = 'DELETE_WORKSPACE';

	//billing
	export const GET_BILLING_DETAILS = 'GET_BILLING_DETAILS';
	export const GET_PAYMENTS = 'GET_PAYMENTS';
	export const GET_AVAILABLE_PRODUCTS = 'GET_AVAILABLE_PRODUCTS';
	export const CHANGE_SUBSCRIPTION = 'CHANGE_SUBSCRIPTION';
	export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
	export const DELETE_PAYMENT = 'DELETE_PAYMENT';
	export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
	export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';

	//tickets
	export const LOAD_TICKETS_SUCCESS = 'LOAD_TICKETS_SUCCESS';
	export const LOAD_TICKETS_FAIL = 'LOAD_TICKETS_FAIL';

	//settings
	export const GET_WORKSPACE_SETTINGS_SUCCESS = 'GET_WORKSPACE_SETTINGS_SUCCESS';
	export const GET_WORKSPACE_SETTINGS_FAIL = 'GET_WORKSPACE_SETTINGS_FAIL';

//locations
export const GET_LOCATIONS_SUCCESS = 'GET_LOCATIONS_SUCCESS';
export const GET_MORE_LOCATIONS_SUCCESS = 'GET_MORE_LOCATIONS_SUCCESS';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_FAIL = 'CREATE_LOCATION_FAIL';
export const UPDATE_LOCATION_SUCCESS = 'UPDATE_LOCATION_SUCCESS';
export const UPDATE_LOCATION_FAIL = 'UPDATE_LOCATION_FAIL';
export const DELETE_LOCATION = 'DELETE_LOCATION';
export const FILTER_LOCATIONS = 'FILTER_LOCATIONS';
export const CLEAR_FILTER_LOCATIONS = 'CLEAR_FILTER_LOCATIONS';

	//location - address
	export const CREATE_LOCATION_ADDRESS_SUCCESS = 'CREATE_LOCATION_ADDRESS_SUCCESS';
	export const CREATE_LOCATION_ADDRESS_FAIL = 'CREATE_LOCATION_ADDRESS_FAIL';
	export const UPDATE_LOCATION_ADDRESS_SUCCESS = 'UPDATE_LOCATION_ADDRESS_SUCCESS';
	export const UPDATE_LOCATION_ADDRESS_FAIL = 'UPDATE_LOCATION_ADDRESS_FAIL';
	export const DELETE_LOCATION_ADDRESS_SUCCESS = 'DELETE_LOCATION_ADDRESS_SUCCESS';
	export const DELETE_LOCATION_ADDRESS_FAIL = 'DELETE_LOCATION_ADDRESS_FAIL';

	//location - phone
	export const CREATE_LOCATION_PHONE_SUCCESS = 'CREATE_LOCATION_PHONE_SUCCESS';
	export const CREATE_LOCATION_PHONE_FAIL = 'CREATE_LOCATION_PHONE_FAIL';
	export const UPDATE_LOCATION_PHONE_SUCCESS = 'UPDATE_LOCATION_PHONE_SUCCESS';
	export const UPDATE_LOCATION_PHONE_FAIL = 'UPDATE_LOCATION_PHONE_FAIL';
	export const DELETE_LOCATION_PHONE_SUCCESS = 'DELETE_LOCATION_PHONE_SUCCESS';
	export const DELETE_LOCATION_PHONE_FAIL = 'DELETE_LOCATION_PHONE_FAIL';

//appointments
export const GET_APPOINTMENTS_CALENDAR = 'GET_APPOINTMENTS_CALENDAR';
export const GET_APPOINTMENTS = 'GET_APPOINTMENTS';
export const GET_APPOINTMENT = 'GET_APPOINTMENT';
export const GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS = 'GET_FUTURE_APPOINTMENTS_CALENDAR_SUCCESS';
export const GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL = 'GET_FUTURE_APPOINTMENTS_CALENDAR_FAIL';
export const GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS = 'GET_PAST_APPOINTMENTS_CALENDAR_SUCCESS';
export const GET_PAST_APPOINTMENTS_CALENDAR_FAIL = 'GET_PAST_APPOINTMENTS_CALENDAR_FAIL';

	//calendar
	export const GET_APPOINTMENTS_CALENDAR_SUCCESS = 'GET_APPOINTMENTS_CALENDAR_SUCCESS';
	export const GET_APPOINTMENTS_CALENDAR_FAIL = 'GET_APPOINTMENTS_CALENDAR_FAIL';

//customers
export const LOAD_CUSTOMERS_SUCCESS = 'LOAD_CUSTOMERS_SUCCESS';
export const LOAD_MORE_CUSTOMERS_SUCCESS = 'LOAD_MORE_CUSTOMERS_SUCCESS';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAIL = 'CREATE_CUSTOMER_FAIL';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_FAIL = 'UPDATE_CUSTOMER_FAIL';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const FILTER_CUSTOMERS = 'FILTER_CUSTOMERS';
export const CLEAR_FILTER_CUSTOMERS = 'CLEAR_FILTER_CUSTOMERS';

	//customers - address
	export const CREATE_CUSTOMER_ADDRESS_SUCCESS = 'CREATE_CUSTOMER_ADDRESS_SUCCESS';
	export const CREATE_CUSTOMER_ADDRESS_FAIL = 'CREATE_CUSTOMER_ADDRESS_FAIL';
	export const UPDATE_CUSTOMER_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_ADDRESS_SUCCESS';
	export const UPDATE_CUSTOMER_ADDRESS_FAIL = 'UPDATE_CUSTOMER_ADDRESS_FAIL';
	export const DELETE_CUSTOMER_ADDRESS_SUCCESS = 'DELETE_CUSTOMER_ADDRESS_SUCCESS';
	export const DELETE_CUSTOMER_ADDRESS_FAIL = 'DELETE_CUSTOMER_ADDRESS_FAIL';

	//customers - phone
	export const CREATE_CUSTOMER_PHONE_SUCCESS = 'CREATE_CUSTOMER_PHONE_SUCCESS';
	export const CREATE_CUSTOMER_PHONE_FAIL = 'CREATE_CUSTOMER_PHONE_FAIL';
	export const UPDATE_CUSTOMER_PHONE_SUCCESS = 'UPDATE_CUSTOMER_PHONE_SUCCESS';
	export const UPDATE_CUSTOMER_PHONE_FAIL = 'UPDATE_CUSTOMER_PHONE_FAIL';
	export const DELETE_CUSTOMER_PHONE_SUCCESS = 'DELETE_CUSTOMER_PHONE_SUCCESS';
	export const DELETE_CUSTOMER_PHONE_FAIL = 'DELETE_CUSTOMER_PHONE_FAIL';

	//customers - appointments
	export const LOAD_CUSTOMER_APPOINTMENTS_SUCCESS = 'LOAD_CUSTOMER_APPOINTMENTS_SUCCESS';
	export const LOAD_MORE_CUSTOMER_APPOINTMENTS_SUCCESS = 'LOAD_MORE_CUSTOMER_APPOINTMENTS_SUCCESS';

//services
export const LOAD_SERVICES_SUCCESS = 'LOAD_SERVICES_SUCCESS';
export const LOAD_MORE_SERVICES_SUCCESS = 'LOAD_MORE_SERVICES_SUCCESS';
export const GET_SERVICE_SUCCESS = 'GET_SERVICE_SUCCESS';
export const CREATE_SERVICE_SUCCESS = 'CREATE_SERVICE_SUCCESS';
export const CREATE_SERVICE_FAIL = 'CREATE_SERVICE_FAIL';
export const UPDATE_SERVICE_SUCCESS = 'UPDATE_SERVICE_SUCCESS';
export const UPDATE_SERVICE_FAIL = 'UPDATE_SERVICE_FAIL';
export const DELETE_SERVICE = 'DELETE_SERVICE';
export const FILTER_SERVICES = 'FILTER_SERVICES';
export const CLEAR_FILTER_SERVICES = 'CLEAR_FILTER_SERVICES';

//schedules
export const LOAD_SCHEDULES_BY_RESOURCE_SUCCESS = 'LOAD_SCHEDULES_BY_RESOURCE_SUCCESS';
export const LOAD_SCHEDULES_BY_RESOURCE_FAIL = 'LOAD_SCHEDULES_BY_RESOURCE_FAIL';

export const GET_SCHEDULES = 'GET_SCHEDULES';
export const GET_SCHEDULE = 'GET_SCHEDULE';
export const LOAD_SCHEDULES_SUCCESS = 'LOAD_SCHEDULES_SUCCESS';
export const LOAD_MORE_SCHEDULES_SUCCESS = 'LOAD_MORE_SCHEDULES_SUCCESS';
export const GET_SCHEDULE_SUCCESS = 'GET_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_SUCCESS = 'CREATE_SCHEDULE_SUCCESS';
export const CREATE_SCHEDULE_FAIL = 'CREATE_SCHEDULE_FAIL';
export const UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS';
export const UPDATE_SCHEDULE_FAIL = 'UPDATE_SCHEDULE_FAIL';
export const DELETE_SCHEDULE = 'DELETE_SCHEDULE';
export const FILTER_SCHEDULES = 'FILTER_SCHEDULES';
export const CLEAR_FILTER_SCHEDULES = 'CLEAR_FILTER_SCHEDULES';

//groups
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUP_SUCCESS = 'GET_GROUP_SUCCESS';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAIL = 'CREATE_GROUP_FAIL';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAIL = 'UPDATE_GROUP_FAIL';
export const DELETE_GROUP = 'DELETE_GROUP';
export const FILTER_GROUPS = 'FILTER_GROUPS';
export const CLEAR_FILTER_GROUPS = 'CLEAR_FILTER_GROUPS';

//custom
export const GET_CUSTOM_OBJECTS = 'GET_CUSTOM_OBJECTS';
export const GET_CUSTOM_OBJECT = 'GET_CUSTOM_OBJECT';
export const GET_CUSTOM_ATTRIBUTES = 'GET_CUSTOM_ATTRIBUTES';
export const GET_CUSTOM_OBJECT_DATA_LIST = 'GET_CUSTOM_OBJECT_DATA_LIST';
export const GET_CUSTOM_OBJECT_DATA = 'GET_CUSTOM_OBJECT_DATA';
export const CREATE_CUSTOM_SUCCESS = 'CREATE_CUSTOM_SUCCESS';
export const CREATE_CUSTOM_FAIL = 'CREATE_CUSTOM_FAIL';
export const CREATE_CUSTOM_OBJECT_SUCCESS = 'CREATE_CUSTOM_OBJECT_SUCCESS';
export const CREATE_CUSTOM_OBJECT_FAIL = 'CREATE_CUSTOM_OBJECT_FAIL';
export const UPDATE_CUSTOM_OBJECT_SUCCESS = 'UPDATE_CUSTOM_OBJECT_SUCCESS';
export const UPDATE_CUSTOM_OBJECT_FAIL = 'UPDATE_CUSTOM_OBJECT_FAIL';
export const DELETE_CUSTOM_OBJECT_SUCCESS = 'DELETE_CUSTOM_OBJECT_SUCCESS';
export const DELETE_CUSTOM_OBJECT_FAIL = 'DELETE_CUSTOM_OBJECT_FAIL';
export const CREATE_RESOURCE_SUCCESS = 'CREATE_RESOURCE_SUCCESS';
export const CREATE_RESOURCE_FAIL = 'CREATE_RESOURCE_FAIL';
export const UPDATE_RESOURCE_SUCCESS = 'UPDATE_RESOURCE_SUCCESS';
export const UPDATE_RESOURCE_FAIL = 'UPDATE_RESOURCE_FAIL';
export const DELETE_RESOURCE = 'DELETE_RESOURCE';
export const DELETE_CUSTOM_SUCCESS = 'DELETE_CUSTOM_SUCCESS';
export const DELETE_CUSTOM_FAIL = 'DELETE_CUSTOM_FAIL';

	//custom attributes
	export const CREATE_CUSTOM_ATTRIBUTE_SUCCESS = 'CREATE_CUSTOM_ATTRIBUTE_SUCCESS';
	export const CREATE_CUSTOM_ATTRIBUTE_FAIL = 'CREATE_CUSTOM_ATTRIBUTE_FAIL';
	export const UPDATE_RESOURCE_FIELDS_SUCCESS = 'UPDATE_RESOURCE_FIELDS_SUCCESS';
	export const UPDATE_RESOURCE_FIELDS_FAIL = 'UPDATE_RESOURCE_FIELDS_FAIL';

//settings
export const LOAD_WORKSPACE_SETTINGS_SUCCESS = 'LOAD_WORKSPACE_SETTINGS_SUCCESS';
export const LOAD_WORKSPACE_SETTINGS_FAIL = 'LOAD_WORKSPACE_SETTINGS_FAIL';
export const LOAD_USER_SETTINGS_SUCCESS = 'LOAD_USER_SETTINGS_SUCCESS';
export const LOAD_USER_SETTINGS_FAIL = 'LOAD_USER_SETTINGS_FAIL';
export const LOAD_LOCATION_SETTINGS_SUCCESS = 'LOAD_LOCATION_SETTINGS_SUCCESS';
export const LOAD_LOCATION_SETTINGS_FAIL = 'LOAD_LOCATION_SETTINGS_FAIL';

//statuses
export const LOAD_STATUSES_SUCCESS = 'LOAD_STATUSES_SUCCESS';
export const LOAD_STATUSES_FAIL = 'LOAD_STATUSES_FAIL';
export const GET_STATUS_SUCCESS = 'GET_STATUS_SUCCESS';
export const CREATE_STATUS_SUCCESS = 'CREATE_STATUS_SUCCESS';
export const CREATE_STATUS_FAIL = 'CREATE_STATUS_FAIL';
export const UPDATE_STATUS_SUCCESS = 'UPDATE_STATUS_SUCCESS';
export const UPDATE_STATUS_FAIL = 'UPDATE_STATUS_FAIL';
export const DELETE_STATUS = 'DELETE_STATUS';

//permissions
export const GET_PERMISSONS = 'GET_PERMISSONS'; // <-- sigh
export const LOAD_ACCESS_DETAILS = 'LOAD_ACCESS_DETAILS';
export const LOAD_PERMISSIONS_BY_ROLE = 'LOAD_PERMISSIONS_BY_ROLE';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_FAILED = 'CREATE_ROLE_FAILED';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';
export const UPLOAD_PERMISSIONS_FOR_ROLE = 'UPLOAD_PERMISSIONS_FOR_ROLE';
export const DELETE_ROLE = 'DELETE_ROLE';

export const SET_LOADING = 'SET_LOADING';

//articles
export const GET_ARTICLES = 'GET_ARTICLES';

//bot
export const GET_BOT_CHATS = 'GET_BOT_CHATS';

//alert
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

//action
export const GET_ACTION = 'GET_ACTION';

//activity
export const LOAD_ACTIVITY_SUCCESS = 'LOAD_ACTIVITY_SUCCESS';
export const LOAD_ACTIVITY_ERROR = 'LOAD_ACTIVITY_ERROR';

//loading
export const SET_INITIAL_PAGE_LOADING = 'SET_INITIAL_PAGE_LOADING';
export const SET_DATA_LOADING = 'SET_DATA_LOADING';
export const SET_PAGE_LOADING = 'SET_PAGE_LOADING';