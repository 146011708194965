import React from 'react';
import { FormHelperText, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import { Controller } from 'react-hook-form';
import { makeStyles } from "@mui/styles";
import dayjs from 'dayjs';

const useStyles = makeStyles((theme) => ({
	'root': {
		'& .MuiOutlinedInput-root': {
			paddingTop: '6px',
			paddingBottom: '6px'
		},
		'& .MuiOutlinedInput-input': {
			color: '#4E5155',
			padding: '0.75rem 1.25rem',
			fontSize: '1rem',
			lineHeight: '1.5',
			borderRadius: '0.25rem'
		},
	}
}));

const TimeControl = ({form, formControl}) => {
	const classes = useStyles();

	const {
		control,
		formState: { errors },
	} = form;

	return (
		<Controller
			name={formControl.name}
			control={control}
			rules={formControl.validationRules}
			defaultValue={formControl.value ? dayjs(formControl.value) : null}
			render={({ field: { onChange, value }, fieldState: { invalid } }) => (
				<>
					<TimePicker
						minutesStep={formControl.minutesStep ?? 5}
						minTime={formControl.minTime ? dayjs(formControl.minTime) : null}
						maxTime={formControl.maxTime ? dayjs(formControl.maxTime) : null}
						className={classes.root}
						value={value}
						onChange={(newValue) => onChange(newValue ? newValue.toDate() : null)}
						renderInput={(params) => (
							<TextField
								{...params}
								size={formControl.size}
								placeholder={formControl.placeholder ?? ''}
								error={!!errors[formControl.name]}
								helperText={errors[formControl.name]?.message}
							/>
						)}
					/>
					{invalid && (
						<FormHelperText error>
							{errors[formControl.name]?.message}
						</FormHelperText>
					)}
				</>
			)}
		/>
	);
}

export default TimeControl