import {
	LOAD_CUSTOMERS_SUCCESS,
	LOAD_MORE_CUSTOMERS_SUCCESS,
	GET_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_SUCCESS,
	CREATE_CUSTOMER_FAIL,
	UPDATE_CUSTOMER_SUCCESS,
	UPDATE_CUSTOMER_FAIL,
	DELETE_CUSTOMER,
	CREATE_CUSTOMER_ADDRESS_SUCCESS,
	CREATE_CUSTOMER_ADDRESS_FAIL,
	UPDATE_CUSTOMER_ADDRESS_SUCCESS,
	UPDATE_CUSTOMER_ADDRESS_FAIL,
	DELETE_CUSTOMER_ADDRESS_SUCCESS,
	DELETE_CUSTOMER_ADDRESS_FAIL,
	CREATE_CUSTOMER_PHONE_SUCCESS,
	CREATE_CUSTOMER_PHONE_FAIL,
	UPDATE_CUSTOMER_PHONE_SUCCESS,
	UPDATE_CUSTOMER_PHONE_FAIL,
	DELETE_CUSTOMER_PHONE_SUCCESS,
	DELETE_CUSTOMER_PHONE_FAIL,
	SEARCH_TERM,
	CLEAR_SEARCH_TERM,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const customersReducer = (state, action) => {
	switch (action.type) {
		case SEARCH_TERM:
			return {
				...state,
				search: action.payload,
			}
		case LOAD_CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: action.payload.items,
				customersSyncIdentifier: action.payload.syncIdentifier,
				customersNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case LOAD_MORE_CUSTOMERS_SUCCESS:
			return {
				...state,
				customers: [...state.customers.concat(action.payload.items)],
				customersSyncIdentifier: action.payload.syncIdentifier,
				customersNextIdentifier: action.payload.nextIdentifier,
				loading: false,
			}
		case GET_CUSTOMER_SUCCESS:
			return {
				...state,
				customer: action.payload,
				loading: false
			}
		case CREATE_CUSTOMER_SUCCESS:
			return {
				...state,
				customer: action.payload,
				customers: [...state.customers, action.payload],
				success: 'Customer successfully created',
				loading: false
			}
		case CREATE_CUSTOMER_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case UPDATE_CUSTOMER_SUCCESS:
			return {
				...state,
				customer: action.payload,
				customers: state.customers.map(customer =>
					customer.identifier === action.payload.identifier ? action.payload : customer
				),
				success: 'Customer successfully updated',
				loading: false
			}
		case UPDATE_CUSTOMER_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_CUSTOMER:
			return {
				...state,
				customers: state.customers.filter(
					customer => customer.identifier !== action.payload
				),
				loading: false
			}
		case CREATE_CUSTOMER_ADDRESS_SUCCESS:
			return {
				...state,
				success: 'Customer successfully updated',
				loading: false
			}
		case CREATE_CUSTOMER_ADDRESS_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_CUSTOMER_ADDRESS_SUCCESS:
			return {
				...state,
				success: 'Customer address successfully updated',
				loading: false
			}
		case DELETE_CUSTOMER_ADDRESS_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case CREATE_CUSTOMER_PHONE_SUCCESS:
			return {
				...state,
				success: 'Customer successfully updated',
				loading: false
			}
		case CREATE_CUSTOMER_PHONE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case DELETE_CUSTOMER_PHONE_SUCCESS:
			return {
				...state,
				success: 'Customer phone successfully updated',
				loading: false
			}
		case DELETE_CUSTOMER_PHONE_FAIL:
			return {
				...state,
				error: action.payload,
				success: null,
				loading: false
			}
		case RESET_SUCCESS:
			return {
				...state,
				success: null
			}
		case RESET_ERROR:
			return {
				...state,
				error: null
			}
		case SET_LOADING:
			return {
				...state,
				loading: true
			}
		case CLEAR_SEARCH_TERM:
			return {
				...state,
				search: ''
			}
		default:
			return state;
	}
}

export default customersReducer