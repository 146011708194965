import React, { Fragment } from 'react';
import { Controller, useFormContext } from "react-hook-form";
import TextFieldMUI from '@mui/material/TextField';
import FormHelperText from "@mui/material/FormHelperText";
import { makeStyles } from '@mui/styles';
import {InputLabel} from "@mui/material";

const useStyles = makeStyles((theme) => ({
	'root': {
		'& .MuiOutlinedInput-root': {
			paddingTop: '4px',
			paddingBottom: '0px'
		},
		'& .MuiOutlinedInput-input': {
			color: '#4E5155',
			padding: '0.75rem 1.25rem',
			fontSize: '1rem',
			lineHeight: '1.5',
			borderRadius: '0.25rem'
		},
	}
}));

const TextField = ({ name, label, rules, title, defaultValue }) => {
	const classes = useStyles();

	const { formState: { errors }, control } = useFormContext();

	return (
		<Controller
			name={name}
			defaultValue={defaultValue ?? ''}
			control={control}
			rules={rules ?? {}}
			render={({ field, fieldState: { invalid } }) => (
				<>
					{title ? <InputLabel id="demo-simple-select-label" className="m-0 p-0">{title}</InputLabel> : ''}
					<TextFieldMUI
						{...field}
						variant="outlined"
						fullWidth
						label={label ?? undefined}
						//className={classes['root']}
						size="small"
					/>
					{invalid && (
						<FormHelperText error>{errors[name]?.message}</FormHelperText>
					)}
				</>
			)}
		/>
	);
};

export default TextField;