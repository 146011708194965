import React, { useState } from 'react';
import { Controller } from "react-hook-form";
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from 'react-phone-number-input/input';
import { FormHelperText } from "@mui/material";

const PhoneControl = ({form, formControl}) => {
	const {
		formState: {},
		control
	} = form;

	return (
		<Controller
			name={formControl.name}
			control={control}
			defaultValue={formControl.value ?? null}
			rules={
				{
					validate: (value) => isValidPhoneNumber(value ?? '')
				}
			}
			render={({ field: { onChange, value }, fieldState: { invalid } }) => {
				return (
					<>
						<PhoneInput
							name={formControl.name}
							className="form-control form-control-lg"
							value={(value === undefined || value === '') ? null : value}
							onChange={onChange}
							// defaultCountry="US"
						/>
						{invalid && (
							<FormHelperText error>Phone number is not valid</FormHelperText>
						)}
					</>
			)}}
		/>
	);
}

export default PhoneControl;