import React, { useReducer } from "react";
import AppointmentCreateContext from "./appointmentCreateContext";
import AppointmentCreateReducer from "./appointmentCreateReducer";

const AppointmentCreateState = props => {
	const initialState = {
		appointment: {
			customers: [],
			instances: [{
				location: null,
				services: [],
				resources: [],
				startsOn: null,
				endsOn: null
			}]
		},
		display: false,
		loading: true
	}

	const [ state, dispatch ] = useReducer(AppointmentCreateReducer, initialState);

	const showModal = (display) => {
		dispatch({
			type: 'appointment_modal_display',
			payload: display
		});
	};

	const setCustomer = (customer) => {
		dispatch({
			type: 'set_customer',
			payload: customer
		});
	};

	const resetCustomers = () => {
		dispatch({
			type: 'reset_customers'
		});
	};

	const setInstance = (instance) => {
		dispatch({
			type: 'set_instance',
			payload: instance
		});
	};

	const resetInstances = () => {
		dispatch({
			type: 'reset_instances'
		});
	};

	const setService = (instanceKey, service) => {
		dispatch({
			type: 'set_service',
			payload: {instanceKey: instanceKey, service: service}
		});
	};

	const resetServices = (instanceKey) => {
		dispatch({
			type: 'reset_services',
			payload: {instanceKey: instanceKey}
		});
	};

	const setResource = (instanceKey, resource) => {
		dispatch({
			type: 'set_resource',
			payload: {instanceKey: instanceKey, resource: resource}
		});
	};

	const resetResources = (instanceKey) => {
		dispatch({
			type: 'reset_resources',
			payload: {instanceKey: instanceKey}
		});
	};

	const setStartsOn = (instanceKey, startsOn) => {
		dispatch({
			type: 'set_starts_on',
			payload: {instanceKey: instanceKey, startsOn: startsOn}
		});
	};

	const resetStartsOn = (instanceKey) => {
		dispatch({
			type: 'reset_starts_on',
			payload: {instanceKey: instanceKey}
		});
	};

	const setLoading = () => dispatch({ type: 'loading' });

	return <AppointmentCreateContext.Provider
		value={{
			state,
			display: state.display,
			appointment: state.appointment,
			showModal,
			setCustomer,
			resetCustomers,
			setInstance,
			resetInstances,
			setService,
			resetServices,
			setResource,
			resetResources,
			setStartsOn,
			resetStartsOn,
			setLoading
		}}
	>
		{props.children}
	</AppointmentCreateContext.Provider>
}

export default AppointmentCreateState;