import React, { useReducer } from "react";
import api from "../../network/Api";
import ResourcesContext from "./resourcesContext";
import ResourcesReducer from "./resourcesReducer";
import {
	GET_RESOURCES,
	RESET_SUCCESS,
	RESET_ERROR,
	SET_LOADING
} from "../types";

const ResourcesState = props => {
	const initialState = {
		resources: null,
		status: null,
		success: null,
		error: null,
		loading: false
	}

	const [ state, dispatch ] = useReducer(ResourcesReducer, initialState);

	const getResources = async () => {
		setLoading();

		await api.get(`/core/v1/resources`, {
			validateStatus: function (status) {
				return status === 200;
			}
		})
		.then(function (response) {
			dispatch({
				type: GET_RESOURCES,
				payload: response.data
			});
		})
		.catch(function (error) {
			dispatch({
				type: GET_RESOURCES,
				payload: []
			});
		});
	};

	const resetSuccess = () => dispatch({ type: RESET_SUCCESS });

	const resetError = () => dispatch({ type: RESET_ERROR });

	const setLoading = () => dispatch({ type: SET_LOADING });

	return <ResourcesContext.Provider
		value={{
			resources: state.resources,
			status: state.status,
			success: state.success,
			error: state.error,
			loading: state.loading,
			getResources,
			resetSuccess,
			resetError,
			setLoading
		}}
	>
		{props.children}
	</ResourcesContext.Provider>
}

export default ResourcesState