import React, { useContext, useEffect } from 'react'
import { Route, Redirect } from 'react-router-dom'

import AuthContext from '../context/auth/authContext'

const LoggedInRoute = ({ ...rest }) => {
    const authContext = useContext(AuthContext);

    const { loading, loadAuthentication, authenticationToken } = authContext;

    useEffect(() => {
        loadAuthentication(localStorage.getItem('token'), localStorage.getItem('workspaceIdentifier'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <div />;
    }

    if (authenticationToken === null) {
        return <Redirect to='/auth/login'/>
    }

    return <Route {...rest} />
}

export default LoggedInRoute