import React, { useContext, useState } from 'react';
import { Button, SimpleModal } from "../../../components/layout/BotSchedulingUI";

// import AppointmentCreateContext from "../../../context/appointmentCreate/appointmentCreateContext";
import ResourceManagementLabel from "../../../utils/ResourceManagementLabel";
import AppointmentCreate from "../components/create/AppointmentCreate";

const CreateAppointmentModal = ({...props}) => {
	// const appointmentCreateContext = useContext(AppointmentCreateContext);
	//
	// const appointmentSingular = ResourceManagementLabel('apt', 'singular', 'case');
	// const appointmentPlural = ResourceManagementLabel('apt', 'plural', 'case');
	//
	// const handleCloseModal = () => {
	// 	appointmentCreateContext.showModal(false);
	// };
	//
	// return (
	// 	<SimpleModal
	// 		header={`Create ${appointmentSingular}`}
	// 		show={appointmentCreateContext.display}
	// 		padding="p-0"
	// 		size="xl"
	// 		onHide={handleCloseModal}
	// 	>
	// 		<AppointmentCreate appointmentSingular appointmentPlural />
	// 	</SimpleModal>
	// );
	return null;
}

export default CreateAppointmentModal;