import React, { useState } from 'react';
import api from "../../../network/Api";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

const DemoDataBanner = ({workspaceIdentifier, ...props}) => {
	const [clearDemoDataIndicator, setClearDemoDataIndicator] = useState(false);
	const [visible, setVisible] = useState(true);

	const clearSampleData = async (workspaceIdentifier) => {
		setClearDemoDataIndicator(true);

		api.post(`/core/v2/workspaces/${workspaceIdentifier}/remove-demo-data`, null,
			{
				validateStatus: function (status) {
					return status === 200;
				}
			}
		).then(function (response) {
			setClearDemoDataIndicator(false);
			setVisible(false);
		}).catch(function (error) {
			setClearDemoDataIndicator(false);
			props.alertContext.setAlert("Error", "Unable to clear sample data.", "danger");
		});
	}

	if (!visible) {
		return null;
	}

	return (
		<div className="p-2 bg-dark font-weight-bold text-white">
			{clearDemoDataIndicator &&
				<Spinner animation="border" size="sm" variant="white" className="mr-2"/>
			}
			This workspace is displaying sample data.
			{!clearDemoDataIndicator &&
				<Link to="#" onClick={() => clearSampleData(workspaceIdentifier)} className="ml-1">Clear
					all sample data.</Link>
			}
		</div>
	);
}

export default DemoDataBanner;