import React from 'react';
import { Box, CircularProgress } from "@mui/material";

const Loading = ({ ready, size = 0, label = null, fillScreenHeight = false, children }) => {
	const paddingClass = size > 0 && size <= 5 ? `p-${size}` : '';

	if (ready) {
		return <>{children}</>;
	} else {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				minHeight={fillScreenHeight ? "100vh" : "auto"}
				height="100%"
				flexDirection="column"
				className={`my-4 ${paddingClass}`}
			>
				<CircularProgress className="text-lighter" />
				{label &&
					<div className="small text-muted mt-2">{label}</div>
				}
			</Box>
		);
	}
}

export default Loading;