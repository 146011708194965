import React, { useReducer } from "react";
import { v4 as uuid } from "uuid";
import AlertContext from "./alertContext";
import alertReducer from "./alertReducer";
import {
	SET_ALERT,
	REMOVE_ALERT
} from "../types";

const AlertState = props => {
	const initialState = [];

	const [ state, dispatch ] = useReducer(alertReducer, initialState);

	// Set Alert
	const setAlert = (title, message, type) => {
		const id = uuid();

		dispatch({
			type: SET_ALERT,
			payload: { title, message, type, id }
		});

		// setTimeout(() => dispatch({
		// 	type: REMOVE_ALERT,
		// 	payload: id })
		// , timeout)
	}

	// Remove Alert
	const removeAlert = (alert) => {
		dispatch({
			type: REMOVE_ALERT,
			payload: alert.id
		})
	}

	return (
		<AlertContext.Provider
			value={{
				alerts: state,
				setAlert,
				removeAlert
			}}>

			{ props.children }
		</AlertContext.Provider>
	);
};

export default AlertState;